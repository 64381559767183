
import '../App.css'
import Container from '@mui/material/Container';
import * as React from 'react';
import Header from "../Home/Header";
import './case-study.scss';
import ScrollToTop from '../Home/ScrollToTop';
import Footer from '../Home/Sponsors';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';


function CaseBibiliography({ changeIrish, setChangeIrish }) {

    return (
        <>

            <ScrollToTop />

            <Header changeIrish={changeIrish} setChangeIrish={setChangeIrish} />

            <div className='case-wrapper'>
                <>
                    <div className='case-study-top-wrapper'>
                        <div className='case-study-title-wrapper'>
                            <h1 className="header-title" style={{ fontSize: '5.5rem' }}>
                                {changeIrish ? <strong>Leabharliosta</strong> :
                                    <strong>MACMORRIS: Bibliography</strong>}
                            </h1>
                        </div>
                        <div className="title-img-wrapper">
                            <img src="/images/case-studies/bibilio.webp" alt="" style={{
                                opacity: 0.5, width: "auto",
                                height: "600px", margin: 10
                            }} />
                        </div>
                    </div>
                    <React.Fragment>
                        <CssBaseline />
                        <Container fixed>
                            <Box>
                                <>
                                    <div className='case-flex-column'>
                                        <div className='para-flex'>

                                            {changeIrish ? <p className='para-style'><strong>Cúlra </strong> </p> : <p className='para-style'><strong>Background </strong></p>}


                                            <ul className='unorder-list'>
                                                <li className='bio-list'>
                                                    Tadhg Ó hAnnracháin & Robert Armstrong eds., <i>Community in early modern Ireland</i> (Dublin: Four Courts, 2006).
                                                </li>
                                                <li className='bio-list'>Tamsin Badcoe, <i>Edmund Spenser and the Romance of Space</i> (Manchester: Manchester University Press, 2019).</li>
                                                <li className='bio-list'>David Baker, and Willy Maley, eds.  <i>British Identities and English Renaissance Literature</i> (Cambridge: Cambridge University Press, 2002). </li>
                                                <li className='bio-list'>David Baker, Willy Maley, and Pat Palmer, “‘What ish my network? Introducing MACMORRIS: Digitising cultural activity and
                                                    collaborative networks in early modern Ireland.” <i>Literature Compass 15</i> (2018): (<a href='https://onlinelibrary.wiley.com/doi/10.1111/lic3.12496' target='_blank noopener'>https://onlinelibrary.wiley.com/doi/10.1111/lic3.12496</a>).  </li>
                                                <li className='bio-list'>David Baker, Willy Maley, and Pat Palmer, “Enter MACMORRIS.” <i>Dublin Review of Books.</i> (2019)
                                                    (<a href='https://drb.ie/articles/enter-macmorris/' target='_blank noopener'>https://drb.ie/articles/enter-macmorris/</a>). </li>
                                                <li className='bio-list'>David Baker, and Pat Palmer, <i>Early Modern Criticism in a Time of Crisis</i> (EMC Imprint, 2022):
                                                    (<a href='https://emctc.tome.press/' target='_blank noopener'>https://emctc.tome.press/</a>) </li>

                                                <li className='bio-list'>Toby Barnard, <i>A Guide to the Sources for Irish Material Culture: 1500-1900</i> (Maynooth: Four Courts Press, 2005). </li>
                                                <li className='bio-list'>Osborn Bergin, <i>Irish Bardic Poetry: Texts and Translations</i> (Dublin: Dublin Institute of Advanced Studies, 2003). </li>
                                                <li className='bio-list'>Ciaran Brady, and Jane Ohlmeyer, eds. <i>British Interventions in Early Modern Ireland</i> (Cambridge: Cambridge University Press, 2005.) </li>
                                                <li className='bio-list'>Brendan Bradshaw ed., <i>And so Began the Irish Nation: Nationality, Nationalism and National Consciousness in
                                                    Pre-Modern Ireland</i> (Farnham: Ashgate, 2015). </li>
                                                <li className='bio-list'>Marc Caball, <i>Poets and politics: continuity and change in Irish poetry</i> (Cork University Press, 1998). </li>
                                                <li className='bio-list'>Marc Caball, ‘Creating an Irish Identity: Print, Culture, and the Irish Franciscans of Louvain’, in <i>Forming Catholic Communities: Irish, Scots and English College Networks in Europe, 1568-1918</i>, ed. Liam Chambers and Thomas O'Connor (Leiden: Brill, 2018). </li>

                                                <li className='bio-list'>Eve Campbell, Elizabeth Fitzpatrick and Audrey Horning, eds., <i>Becoming and Belonging in Ireland 1200-1600</i> (Cork University Press, 2018). </li>
                                                <li className='bio-list'>David Cannadine, “British National Biography and Global British Lives: From the DNB to the ODNB – and Beyond”, in <i>‘True Biographies of Nations?’: The Cultural Journeys of Dictionaries of National Biography</i>,
                                                    edited by Karen Fox, 193-208. (ANU Press, 2019). (<a href='http://doi.org/10.22459/TBN.2019.11' target='_blank noopener'>http://doi.org/10.22459/TBN.2019.11</a>.) </li>

                                                <li className='bio-list'>Ruth Canning, <i>The Old English in Early Modern Ireland: the Palesmen and the Nine Years War, 1594-1603</i> (Woodbridge: Boydell and Brewer, 2019). </li>
                                                <li className='bio-list'>Nicholas Canny, 'The Formation of the Irish Mind: Religion, Politics and Gaelic Literature, 1580-1750', <i>Past and Present 95</i> (1982), 91-116. </li>
                                                <li className='bio-list'>Nicholas Canny, 'Edmund Spenser and the Development of an Anglo-Irish Identity', <i>Yearbook of English Studies 13</i> (1983), 1-19. </li>
                                                <li className='bio-list'>Nicholas Canny, <i>Making Ireland British 1580-1650.</i> (Oxford: Oxford University Press, 2001). </li>
                                                <li className='bio-list'>Nicholas Canny, <i>Imagining Ireland's Pasts: Early Modern Ireland through the Centuries</i> (Oxford: Oxford University Press, 2021). </li>
                                                <li className='bio-list'>Claire Carroll, <i>Exiles in a Global City: The Irish and Early Modern Rome, 1609-1783</i> (Leiden: Brill, 2017). </li>
                                                <li className='bio-list'>Claire Carroll,  <i>Circe’s Cup: Cultural Transformations in Early Modern Writing About Ireland</i>  (Cork: Cork University Press, 2001). </li>
                                                <li className='bio-list'>Aidan Clark, <i>The Old English in Ireland, 1625-42</i> (Ithaca: Cornell University Press, 1966). </li>
                                                <li className='bio-list'>SJ Connolly, <i>Contested Ireland: Ireland 1460-1630</i> (Oxford: Oxford University Press, 2007). </li>
                                                <li className='bio-list'>Maire-Louise Coolahan, <i>Women, Writing, and Language in Early Modern Ireland</i> (Oxford University Press, 2010). </li>
                                                <li className='bio-list'>Patricia Coughlan, ed. <i>Spenser and Ireland: An Interdisciplinary Perspective</i> (Cork: Cork University Press, 1989). </li>
                                                <li className='bio-list'>Sarah Covington, Vincent Carey, and Valerie McGowan-Doyle, <i>Early Modern Ireland: New Sources, Methods, and Perspectives</i> (Abingdon: Routledge, 2019). </li>
                                                <li className='bio-list'>Bernadette Cunningham, <i>The World of Geoffrey Keating: History, Myth and Religion in Seventeenth-Century Ireland</i> (Dublin: Four Courts Press, 2004). </li>
                                                <li className='bio-list'>Bernadette Cunningham, <i>The Annals of the Four Masters: Irish History, Kingship and
                                                    Society in the Early Seventeenth Century</i> (Dublin: Four Courts Press, 2010). </li>
                                                <li className='bio-list'>Patrick J. Duffy, David Edwards and Elizabeth Fitzpatrick, eds., <i>Gaelic Ireland c.1250-c.1650: Land, Lordship and Settlement</i> (Dublin: Four Courts, 2001). </li>
                                                <li className='bio-list'>Seán Duffy ed., <i>Medieval Ireland: An Encyclopedia</i> (Dublin, 2004). </li>
                                                <li className='bio-list'>Steven G Ellis, <i>Tudor Ireland: crown, community, and the conflict of cultures, 1470-1603</i> (London: Longman Publishing Group, 1985). </li>
                                                <li className='bio-list'>Gerard Farrell, <i>The 'Mere Irish' and the Colonisation of Ulster, 1570-1641</i> (Springer, 2017). </li>
                                                <li className='bio-list'>Elizabeth Fitzpatrick, <i>Royal Inauguration in Gaelic Ireland C. 1100-1600: A Cultural Landscape Study</i> (Boydell, 2004). </li>
                                                <li className='bio-list'>Elizabeth Fitzpatrick, ‘Ollamh, biatach, comharba: lifeways of Gaelic learned families in medieval and early modern Ireland’, <i>Proceedings of the
                                                    XIVth International Celtic Congress</i>, Maynooth 2011. </li>
                                                <li className='bio-list'>Elizabeth Fitzpatrick, ‘The landscape and Bardic School settlement of the Uí Dhálaigh Gaelic poets of Muinter Bháire’, in <i>Princes, prelates and poets in medieval Ireland: essays in honour of Katharine Simms</i> (Dublin: Four Courts Press, 2013). </li>
                                                <li className='bio-list'>Elizabeth FitzPatrick and Coilin Ó Drisceoil, ‘The landscape and Gaelic law school settlement of the O’Doran brehons, Ballyorley, Co. Wexford’, in <i>Medieval Wexford</i> (Dublin: Four Courts Press, 2016). </li>
                                                <li className='bio-list'>Raymond Gillespie, <i>Devoted People: Belief and Religion in Early Modern Ireland</i> (Manchester: Manchester University Press, 1997). </li>
                                                <li className='bio-list'>Raymond Gillespie,and Andrew Hadfield, eds. <i>History of the Irish Book, vol. III: 1500-1800</i> (Oxford: Oxford University Press, 2005). </li>
                                                <li className='bio-list'>Andrew Hadfield, <i>Edmund Spenser: A Life</i> (Oxford: Oxford University Press, 2012). </li>
                                                <li className='bio-list'>Thomas Herron, and Michael Potterton, eds., <i>Ireland in the Renaissance c.1540-1660</i> (Dublin: Four Courts Press, 2007). </li>
                                                <li className='bio-list'>Brendan Kane, <i>The Politics and Culture of Honour in Britain and Ireland, 1541-1641</i>
                                                    (Cambridge: Cambridge University Press, 2010). </li>
                                                <li className='bio-list'>Brendan Kane, 'Making the Irish European: Gaelic Honour Politics and its Continental Contexts', <i>Renaissance Quarterly 61</i> (2008), 1139-66.  </li>
                                                <li className='bio-list'>Brendan Kane & Valerie McGowan-Doyle, eds., <i>Elizabeth I and Ireland</i> (Cambridge: Cambridge University Press, 2007). </li>
                                                <li className='bio-list'>John Kerrigan, <i>Archipelagic English: Literature, History, and Politics, 1603-1707</i> (Oxford: Oxford University Press, 2008). </li>
                                                <li className='bio-list'>Colm Lennon, <i>Sixteenth-Century Ireland: The Incomplete Conquest–Irish Landlords and the Extension of English Royal Power</i> (Dublin: Gill & Macmillan, 2005). </li>

                                                <li className='bio-list'>James Lyttleton and Colin Rynne eds., <i>Plantation Ireland: Settlement and Material Culture, c.1550-c.1700</i> (Dublin: Four Courts Press, 2009). </li>
                                                <li className='bio-list'>Christopher Maginn, <i>‘Civilizing’ Gaelic Leinster: The Extension of Tudor Rule in the O'Byrne and O'Toole Lordships</i> (Dublin: Four Courts Press, 2005). </li>
                                                <li className='bio-list'>Anthony M. McCormack, <i>The Earldom of Desmond 1463-1583: The Decline and Crisis of a Feudal Lordship</i> (Dublin: Four Courts Press, 2005). </li>
                                                <li className='bio-list'>Valerie McGowan-Doyle, <i>The Book of Howth: Elizabethan Conquest and the Old English</i> (Cork: Cork University Press, 2011). </li>
                                                <li className='bio-list'>Éamonn Ó Ciardha, 'Irish-language Sources for the History of Early Modern Ireland', in <i>Oxford Handbook of Modern Irish History</i>, ed. Alvin Jackson (Oxford: Oxford University Press, 2014). </li>
                                                <li className='bio-list'>Thomas O'Connor, <i>The Irish in Europe</i> 1580-1815 (Dublin: Four Courts Press, 2001). </li>
                                                <li className='bio-list'>Thomas O'Connor and Liam Chambers, eds., <i>Forming Catholic Communities: Irish, Scots and English College Networks in Europe 1560s-1918</i> (Leiden: Brill, 2018). </li>
                                                <li className='bio-list'>Thomas O'Connor and Liam Chambers, eds., <i>Catholic Communities Abroad: Education, Migration and Catholicism in Early Modern Europe</i> (Manchester: Manchester University Press, 2017). </li>
                                                <li className='bio-list'>James O’Neill, <i>The Nine Years War 1593-1603: O’Neill, Mountjoy and the Military Revolution</i> (Dublin: Four Courts Press, 2017). </li>
                                                <li className='bio-list'>Stephen O’Neill, <i>Staging Ireland: Representations in Shakespeare and Renaissance Drama</i> (Dublin: Four Courts Press, 2007). </li>
                                                <li className='bio-list'>Turlough O’Riordan, “The Irish World: How to Revise a Long-Standing Dictionary
                                                    Project.” In <i>‘True Biographies of Nations?’: The Cultural Journeys of Dictionaries of
                                                        National Biography</i>, ed. by Karen Fox, 37-55. (ANU Press, 2019).
                                                    <a href='https://doi.org/10.22459/TBN.2019.03' target='blank noopener'>https://doi.org/10.22459/TBN.2019.03</a>. </li>
                                                <li className='bio-list'>Patricia Palmer, <i>Language and Conquest in Early Modern Ireland</i> (Cambridge: Cambridge University Press, 2001). </li>
                                                <li className='bio-list'>Patricia Palmer, <i>The Severed Head and the Grafted Tongue: Literature, Translation and Violence in Early Modern Ireland</i> (Cambridge: Cambridge University Press, 2013). </li>
                                                <li className='bio-list'>Deana Rankin, <i>Between Spenser and Swift: English Writing in Seventeenth-Century Ireland</i> (Cambridge: Cambridge University Press, 2005). </li>
                                            </ul>

                                            {changeIrish ? <p className='para-style'><strong>Foinsí Gaeilge</strong></p> : <p className='para-style'><strong> Gaelic Sources</strong> </p>}
                                            <ul className='unorder-list'>
                                                <li className='bio-list'>Osborn Bergin, <i>Irish Bardic Poetry: Texts and Translations</i> (Dublin: Dublin Institute of Advanced Studies, 2003)  </li>
                                                <li className='bio-list'>Caoimhín Breatnach, ‘Early Modern Irish Prose’, in K, McCone & K, Simms (eds), <i>Progress in Medieval Irish Studies</i> (Maynooth, 1996), 189–206. </li>
                                                <li className='bio-list'>Pádraig A. Breatnach, “The Chief’s Poet.” <i>Proceedings of the Royal Irish Academy: Archaeology, Culture, History, Literature 83c</i> (1983): 37-79.  </li>
                                                <li className='bio-list'>Pádraig A. Breatnach, “A Poem on the End of Patronage.” <i>Éigse 31</i> (1999): 79–88. </li>
                                                <li className='bio-list'>James Carney, ‘Society and the Bardic Poet’, <i>Studies: An Irish Quarterly Review 62.247/248</i> (1973), 223–250. </li>
                                                <li className='bio-list'>James Carney, <i>The Irish Bardic Poet: A Study in the Relationship of Poet & Patron as Exemplified in the Persons of the Poet Eochaidh Ó hEoghusa and his Various Patrons, Mainly Members of the Maguire Family of Fermanagh</i> (Dublin: Dublin Institute of Advanced Studies, 1985). </li>
                                                <li className='bio-list'>David Greene, <i>Duanaire Mhéig Uidhir: The Poem-book of Cú Chonnacht Mág Uidhir, Lord of Fermanagh 1566–1589, Edited from the Copenhagen manuscript</i> (Dublin: Dublin Institute of Advanced Studies, 1991). </li>
                                                <li className='bio-list'>Margo Griffin-Wilson, “Fear Feasa Ó’n Cháinte’s Petition for Reconciliation,” in <i>Lorg na Leabhar: A Festschrift for Pádraig A. Breatnach, ed. Caoimhín Breatnach</i>, Meidhbhín Ní Úrdail, Gordon Ó Riain, 235–69. (Dublin: Four Courts Press, 2019.) </li>
                                                <li className='bio-list'>Elinor Knott, <i>Irish syllabic poetry 1200–1600</i> (Cork and Dublin, 1928). </li>
                                                <li className='bio-list'>Elinor Knott, <i>The bardic poems of Tadhg Dall Ó hUiginn</i> (London, 1922–6), esp. vol. 1, li-lxiv (on literary style). </li>
                                                <li className='bio-list'>Prionsias Mac Cana, ‘The Poet as Spouse of His Patron’, <i>Ériu 39</i> (1988), 79–85. </li>
                                                <li className='bio-list'>Terence McCaughey, ‘Bards Beasts and Men’, in D. Ó Corráin, L. Breatnach & K. McCone (eds), <i>Sages, Saints and Storytellers: Celtic Studies in Honour of Professor James Carney</i> (Maynooth 1989), 102–121. </li>
                                                <li className='bio-list'>Mícheál Mac Craith, <i>Lorg na hIasachta ar na dánta grá</i> (Dublin, 1989). </li>
                                                <li className='bio-list'>Sarah E McKibben, “Bardic Close Reading,” in <i>Early Modern Ireland: New Sources, Methods, and Perspectives</i>, ed. Sarah Covington et. al, 96-112. (London: Routledge, 2018). </li>
                                                <li className='bio-list'>Sarah E McKibben, “Guaranteeing What Cannot Be Guaranteed. Defending and Adapting Bardic Patronage in Ag so an Chomairce, a Chormaic (ca. 1585) by Tadhg Dall Ó HUiginn.” <i>North American Journal of Celtic Studies 2.1</i> (2018): 1.
                                                    (<a href='https://doi.org/10.26818/nortamerceltstud.2.1.0001' target='blank noopener'>https://doi.org/10.26818/nortamerceltstud.2.1.0001</a>). </li>
                                                <li className='bio-list'>Damian McManus, ‘Classical Modern Irish’, in K. McCone and K. Simms (eds) <i>Progress in medieval Irish Studies</i> (Maynooth, 1996), 165–87. </li>
                                                <li className='bio-list'>Damian McManus, ‘‘The smallest man in Ireland can reach the tops of her trees’: Images of the King’s Peace and Bounty in Bardic Poetry’, <i>CSANA Yearbook 5</i> (Dublin 2006), 61–116. </li>

                                                <li className='bio-list'>Damian McManus, ‘Surnames and Scions: Adjectival Qualification of Christian Names and Cognomina in Classical Irish Poetry’, <i>Ériu 63</i> (2013), 117–43. </li>
                                                <li className='bio-list'>Damian McManus, ‘Female ancestry and mother’s kin in Classical Irish poetry’, in Caoimhín Breatnach and Meidhbhín Ní Úrdail (eds), <i>Aon don Éigse. Essays marking Osborn Bergin’s centenary lecture on Bardic Poetry</i> (Dublin, 2015), 193–219. </li>
                                                <li className='bio-list'>Damian mcManus, ‘Celebrating the Female in Classica Irish Poetry: The Wife’, <i>Ériu 65</i> (2015), 137–68. </li>
                                                <li className='bio-list'>Damian Mc Manus & Eoin Ó Raghallaigh, eds., <i>A Bardic Miscellany</i> (Dublin, 2010). </li>
                                                <li className='bio-list'>Breandán Ó Buachalla, ‘‘Annála Ríoghachta Éireann’ is ‘Foras Feasa ar Éirinn’: An Comhthéacs Comhaimseartha’, <i>Studia Hibernica 22/23</i> (1982/3), 59–105. </li>
                                                <li className='bio-list'>Breandán Ó Buachalla, ‘Na Stíobhartaigh agus an t-aos léinn: Cing Séamas’, <i>Proceedings of the Royal Irish Academy 83C</i> (1983), 81–134 </li>

                                                <li className='bio-list'>Brian Ó Cuív, ‘The Linguistic Training of the Mediaeval Irish Poet’, <i>Celtica 10</i> (1973), 114-40. </li>
                                                <li className='bio-list'>Brian Ó Cuív ed., <i>Seven Centuries of Irish Learning 1000-1700</i> (Dublin: Stationery Office, 1961) – includes Green ‘The Professional Poets’. </li>
                                                <li className='bio-list'>Pádraig Ó Macháin, ‘The Flight of the Poets: Eóghan Ruadh and Fearghal Óg Mac an Bhaird in Exile’, <i>Seanchas Ard Mhacha 21/2</i> (2007-8), 39-50. </li>
                                                <li className='bio-list'>Cecile O’Rahilly, <i>Five Seventeenth-Century Political Poems</i> (Dublin, 1952). </li>
                                                <li className='bio-list'>Michelle O’Riordan, <i>The Gaelic Mind and the Collapse of the Gaelic World</i> (Cork: Cork
                                                    University Press, 1990). </li>
                                                <li className='bio-list'>Michelle O’Riordan, <i>Irish Bardic Poetry and Rhetorical Reality</i> (Cork: Cork University Press, 2007). </li>
                                                <li className='bio-list'>Michelle O’Riordan, <i>Poetics and Polemics: Reading Seventeenth-Century Irish Political Verse</i> (Cork: Cork University Press, 2021). </li>
                                                <li className='bio-list'>Katharine Simms, <i>Medieval Gaelic sources</i> (Dublin: Four Courts Press, 2009). </li>
                                                <li className='bio-list'>Katharine Simms, 'The Brehons in Later medieval Ireland', in <i>Brehons, Sergeants and Attorneys</i>, ed. D Hogan and W. Osborough (Blackrock: Irish Academic Press, 1990). </li>
                                                <li className='bio-list'>Katharine Simms, 'Bards and Barons: the Anglo-Irish aristocracy and the native culture',
                                                    in <i>Medieval Frontier Societies</i>, ed. Robert Bartlett and Angus Mackay (Oxford: Clarendon, 1992). </li>
                                                <li className='bio-list'>Katharine Simms, 'Literacy and the Irish Bards', in <i>Literacy in Medieval Celtic Societies</i>, ed. H. Pryce (Cambridge: Cambridge University Press, 1998). </li>
                                                <li className='bio-list'>Katharine Simms, 'Charles Lynegar, the Ó Luinín Family and the Study of Seanchas’, in <i>A Miracle of Learning</i>, ed. Toby Barnard (Aldershot: Ashgate, 1998). </li>
                                                <li className='bio-list'>Katharine Simms, <i>From kings to warlords: the changing political structure of Gaelic Ireland in the later middle ages</i> (London: Boydell & Brewer, 2000). </li>
                                                <li className='bio-list'>Katharine Simms, 'Bardic Schools, Learned Families', in Seán Duffy ed., <i>Medieval Ireland: An Encyclopedia</i> (Dublin, 2004). </li>

                                                <li className='bio-list'>Katharine Simms, ‘The Poetic Brehon Lawyers of the early 16th century Ireland’, <i>Ériu 57</i> (2007), 121-32. </li>
                                                <li className='bio-list'>Katharine Simms, ‘The Barefoot Kings: Literary Image and Reality in Later Medieval Ireland’, <i>Proceedings of the Harvard Celtic Colloquium 30</i> (2010) 1-21. </li>
                                                <li className='bio-list'>Katharine Simms, ‘Foreign Apologues in Bardic Poetry’, in <i>The English Isles: cultural
                                                    transmission and political conflict in Britain and Ireland, 1100–1500</i>, ed. Seán Duffy and Susan Foran (Dublin: Four Courts Press. 2013).</li>
                                            </ul>

                                            {changeIrish ? <p className='para-style'><strong>Líonraí</strong></p> : <p className='para-style'><strong>Network Analysis</strong> </p>}
                                            <ul className='unorder-list'>
                                                <li className='bio-list'>Ruth Ahnert, and Sebastian E. Ahnert, “Protestant Letter Networks in the Reign of Mary I: A Quantitative Approach.” <i>ELH 82.1</i> (2015): 1-27. </li>
                                                <li className='bio-list'>Ruth Ahnert, and Sebastian E. Ahnert, “Metadata, Surveillance and the Tudor State.” <i>History Workshop Journal</i> (2019): 1-26. </li>
                                                <li className='bio-list'>Ruth Ahnert, Sebastian E. Ahnert, Catherine Nicole Coleman, and Scott B. Weingart, <i>The Network Turn: Changing Perspectives in the Humanities</i> (Cambridge: Cambridge University Press, 2020.) </li>

                                                <li className='bio-list'>Bernhard Bauer, “Venezia, Biblioteca Marciana, Zanetti lat. 349 an Isolated Manuscript? A (Network) Analysis of Parallel Glosses on Orosius' Historiae Adversus Paganos.” <i>Etudes Celtique 45</i> (2019):91-106. </li>
                                                <li className='bio-list'>Stephen Borgatti, “Centrality and Network Flow.” <i>Social Networks 27</i> (2005): 55–71.  </li>

                                                <li className='bio-list'>Evan Bourke, “Female Involvement, Membership, and Centrality: A Social Network Analysis of the Hartlib Circle.” <i>Literature Compass 14.4</i> (2017): 1-17. </li>
                                                <li className='bio-list'>Evan Bourke, “Networking Early Modern Irish Women.” <i>Irish Historical Studies 46, no.170</i> (2022): 270-85. </li>
                                                <li className='bio-list'>Evan Bourke and Deirdre Nic Chárthaigh, “Patronage networks in Gaelic Ireland ca. 1541 – ca. 1660”, <i>Renaissance Quarterly</i> (Forthcoming).</li>
                                                <li className='bio-list'>Blaine Greteman, <i>Networking Print in Shakespeare’s England: Influence, Agency, and
                                                    Revolutionary Change</i> (Stanford: Stanford University Press, 2021). </li>
                                                <li className='bio-list'>John R. Ladd, Jessica Otis, Christopher N. Warren, and Scott Weingart. “Exploring
                                                    and Analyzing Network Data with Python.” <i>Programming Historian 6</i> (2017):
                                                    <a href='https://doi.org/10.46430/phen0064' target='blank noopener'>https://doi.org/10.46430/phen0064</a>. </li>

                                                <li className='bio-list'>Bronagh Ann McShane, “Visualising the Reception and Circulation of Early Modern Nuns’ Letters.” <i>Journal of Historical Network Research 2 </i>(2018): 1-25. </li>
                                                <li className='bio-list'>Catherine Medici, "Using network analysis to understand early modern women." <i>Early Modern Women: An Interdisciplinary Journal 13.1 </i>(2018): 153-162. </li>
                                                <li className='bio-list'>Mark E. Newman, <i>Networks: An Introduction</i> (Oxford: Oxford University Press, 2018). </li>

                                                <li className='bio-list'>Mark E. Newman, “The Structure and Function of Complex Networks.” <i>SIAM Review 45.2</i> (2003): 167-256. </li>
                                                <li className='bio-list'>Tore Opshal, “Triadic closure in two-mode networks: Redefining the global and local
                                                    clustering coefficients.” <i>Social Networks 35.2</i> (2013): 159-167. </li>

                                                <li className='bio-list'>Yann C. Ryan, and Sebastian E. Ahnert, “The Measure of the Archive: The Robustness of Network Analysis in Early Modern Correspondence.” <i>Journal of Cultural Analytics 7 </i>(2021): 57-88. </li>
                                                <li className='bio-list'>Minna Tamper, Petri Leskinen, and Eero Hyvönen, “Visualizing and Analyzing Networks of
                                                    Named Entities in Biographical Dictionaries for Digital Humanities Research” (2019): <a href='https://doi.org/10.29007/zqs5' target='blank noopener'>https://doi.org/10.29007/zqs5</a>. </li>

                                                <li className='bio-list'>Minna Tamper, Petri Leskinen, Kasper Apajalahti, and Eero Hyvönen. <i>“Using Biographical
                                                    Texts as Linked Data</i> for Prosopographical Research and Applications,” in <i>Digital Heritage.
                                                        Progress in Cultural Heritage: Documentation, Preservation, and Protection</i>, ed. by Marinos Ioannides et al, 125–37. (Cham: Springer International Publishing, 2018): <a href='https://doi.org/10.1007/978-3-030-01762-0_11' target='blank noopener'>https://doi.org/10.1007/978-3-030-01762-0_11</a>.
                                                </li>

                                                <li className='bio-list'>Jenna Townend, “Quantitative and Qualitative Approaches to Early-modern Networks: The Case of George Herbert (1593–1633) and his Imitators.” <i>Literature Compass 14.3</i> (March 2017): 1-14. </li>
                                                <li className='bio-list'>Esther Van Raamsdonk, and Ruth Ahnert, “John Milton’s Network and the Republic of
                                                    Letters”. <i>Renaissance and Reformation / Renaissance et Réforme 44.3</i> (2021): 81–110. </li>

                                                <li className='bio-list'>Ingeborg Van Vugt, “Using Multi-Layered Networks to Disclose Books in the Republic of
                                                    Letters.” <i>Journal of Historical Network Research 1</i> (2017): 25-51. </li>

                                                <li className='bio-list'>Ingeborg Van Vugt,  "Networking in the Republic of Letters: Magliabechi and the Dutch
                                                    Republic." <i>Journal of Interdisciplinary History 53.1</i> (2022): 117-141. </li>

                                                <li className='bio-list'>Christopher Warren et al., “Six Degrees of Francis Bacon: A Statistical Method for
                                                    Reconstructing Large Historical Social Networks,” <i>Digital Humanities Quarterly 10.3</i>
                                                    (2016): 1-15.</li>
                                                <li className='bio-list'>Christopher Warren et al, “Gender and Name Recognition,” “Six Degrees of Francis Bacon,”
                                                    March 20, 2013: <a href='http://6dfb.tumblr.com/post/45833622936/gender-and-namerecognition' target='blank noopener'>http://6dfb.tumblr.com/post/45833622936/gender-and-namerecognition</a>.</li>

                                                <li className='bio-list'>Joseph Yose, Ralph Kenna, Máirín MacCarron, and Pádraig MacCarron, “Network Analysis
                                                    of the Viking Age in Ireland as Portrayed in Cogadh Gaedhel re Gallaibh.” <i>Royal Society Open Science Vol. 5.1</i> (2018):
                                                    <a href='https://doi.org/10.1098/rsos.171024' target='blank noopener'>https://doi.org/10.1098/rsos.171024</a>.</li>
                                            </ul>

                                            {changeIrish ? <p className='para-style'><strong>Léarscáil Dhomhain</strong></p> : <p className='para-style'><strong>Deep Mapping </strong></p>}
                                            <ul className='unorder-list'>
                                                <li className='bio-list'>J. H. Andrews,  “The Mapping of Ireland’s Cultural Landscape, 1550-1630,” in Patrick
                                                    J. Duffy David Edwards & Liz FitzPatrick (eds.), <i>Gaelic Ireland c.1250–c.1650: Land, lordship & settlement</i> (Dublin: Four Courts Press, 2001). </li>

                                                <li className='bio-list'>J. H. Andrews, “Sir Richard Bingham and the Mapping of Western Ireland.” <i>Proceedings of
                                                    the Royal Irish Academy. Section C: Archaeology, Celtic Studies, History, Linguistics, Literature 103C, no. 3</i> (2003): 61–95. </li>

                                                <li className='bio-list'>Chris Barrett, <i>Early Modern English Literature and the Poetics of Cartographic Anxiety</i>
                                                    (Oxford University Press, 2018). </li>
                                                <li className='bio-list'>David J., Bodenhamer,  John Corrigan, and Trevor M. Harris, eds., <i>Making Deep Maps:
                                                    Foundations, Approaches, and Methods</i> (London: Routledge, 2021). </li>

                                                <li className='bio-list'>David J., Bodenhamer, "Making the invisible visible: Place, spatial stories and deep
                                                    maps," <i>Literary mapping in the digital age</i> (London: Routledge, 2016), 225-238. </li>

                                                <li className='bio-list'>David J., Bodenhamer,  John Corrigan, and Trevor M. Harris, <i>Deep maps and spatial
                                                    narratives</i> (Indiana University Press, 2015). </li>

                                                <li className='bio-list'>David J., Bodenhamer,  John Corrigan, and Trevor M. Harris, "Deep mapping and the spatial
                                                    humanities." <i>International Journal of Humanities and Arts Computing 7.1-2</i> (2013): 170-175. </li>
                                                <li className='bio-list'>David J. Bodenhamer,  John Corrigan, and Trevor M. Harris, eds. <i>The spatial humanities:
                                                    GIS and the future of humanities scholarship</i> (Indiana University Press, 2010). </li>

                                                <li className='bio-list'>David Cooper, and Ian N Gregory, “Mapping the English Lake District: A Literary GIS:
                                                    Mapping the English Lake District.” <i>Transactions of the Institute of British Geographers 36, no. 1</i> (January 2011): 89–108.
                                                    <a href='https://doi.org/10.1111/j.1475-5661.2010.00405.x' target='blank noopener'>https://doi.org/10.1111/j.1475-5661.2010.00405.x</a>. </li>

                                                <li className='bio-list'>Imogen Humphris, Lummina G. Horlings, and Iain Biggs, ‘‘Getting Deep into Things’: Deep
                                                    Mapping in a ‘Vacant’ Landscape’, in <i>Co-Creativity and Engaged Scholarship</i>, ed. Alex Franklin (Palgrave Macmillan, 2022). </li>

                                                <li className='bio-list'>Randa El Khatib, and Marcel Schaeben, “Why Map Literature? Geospatial Prototyping for
                                                    Literary Studies and Digital Humanities.” <i>Digital Studies/Le Champ Numérique 10, no. 1</i> (September 21, 2020): 7.
                                                    <a href='https://doi.org/10.16995/dscn.381' target='blank noopener'>https://doi.org/10.16995/dscn.381</a>. </li>
                                                <li className='bio-list'>Silvia Loeffler, “Glas Journal: Deep Mappings of a Harbour or the Charting of Fragments,
                                                    Traces and Possibilities.” <i>Humanities 4, no. 3</i> (September 18, 2015): 457–75.
                                                    <a href='https://doi.org/10.3390/h4030457' target='blank noopener'>https://doi.org/10.3390/h4030457</a>. </li>
                                                <li className='bio-list'>Cliff McLucas, ‘There are ten things that I can say about these deep maps’ (n.d.):
                                                    <a href='http://cliffordmclucas.info/deep-mapping.html' target='blank noopener'>http://cliffordmclucas.info/deep-mapping.html</a></li>
                                                <li className='bio-list'>Mary Modeen, and Iain Biggs, <i>Creative engagements with ecologies of place: Geopoetics,
                                                    Deep mapping and slow residencies</i> (London: Routledge, 2020). </li>
                                                <li className='bio-list'>Catherine Porter et al, 'Cartographic Connections - the digital analysis and curation of
                                                    sixteenth-century maps of Great Britain and Ireland.' <i>e-Perimetron 14</i> (2019): 97-109 </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            </Box>
                        </Container>
                    </React.Fragment>

                </>
            </div >
            <Footer />
        </>
    );
}

export default CaseBibiliography;
