import Biography from "./Biography";
import React, { useState, useEffect } from 'react';
import { API_URL } from '../constants';


function TeamClean({ changeIrish }) {

    const [showID, setShowID] = useState(null);
    const [data, setData] = useState([])

    const handleShow = (e) => {
        setShowID(e)
        console.log("handle show", e)
    }
    const handleClose = () => {
        setShowID(null)
        console.log("handle close");
    }


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const teamRes = await fetch(`${API_URL}/teams`);
        const teamJson = await teamRes.json();
        setData(teamJson);
        console.log(teamJson)
    }

    return (
        <section id="our-team" className="padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 text-center">
                        <div className="heading-title wow fadeInUp" data-wow-delay="300ms">
                            {changeIrish ?
                                <>
                                    <span>Seo í an fhoireann</span>
                                    <h2 className="darkcolor bottom20">Foireann MACMORRIS</h2>
                                </>
                                :

                                <>
                                    <span>Meet the Team</span>
                                    <h2 className="darkcolor bottom20">MACMORRIS Team</h2>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        Object.values(data).map((e, i) =>
                            <div className="col-lg-3 col-md-6" key={e.id}>
                                <div className="team-box top60 wow fadeIn" data-wow-delay="350ms">
                                    <div onClick={() => handleShow(i)}>
                                        <img src={`${e.imgURLPath}`} alt=""
                                            style={{ width: "300px", height: "300px", objectFit: "cover", objectPosition: "center" }} />
                                    </div>
                                    <div className="team-content" >
                                        <h3>{e.name}</h3>
                                        <p className="nomargin">{e.designation}</p>
                                        <Biography show={showID === i} handleClose={handleClose} data={e} changeIrish={changeIrish} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default TeamClean;
