import React from 'react';
import { useEffect, useState } from "react";
import '../Network.scss';
import { Link } from 'react-router-dom';
function PanelTop() {
    return (
        <div className='panel-top'>
            <Link to='/' href="index.html">
                <img style={{ width: 'auto', height: '100px' }} alt="" src="/images/logos/macmorris.png" />
            </Link>
        </div>
    )
}

export default PanelTop;
