
import '../App.css'
import Container from '@mui/material/Container';
import * as React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Link } from 'react-router-dom';
import Header from "../Home/Header";
import './case-study.scss';
import ScrollToTop from '../Home/ScrollToTop';
import Footer from '../Home/Sponsors';
import { Block } from '@material-ui/icons';


function CaseLearningResources({ changeIrish, setChangeIrish }) {

    return (
        <>
            <ScrollToTop />

            <Header changeIrish={changeIrish} setChangeIrish={setChangeIrish} />

            <div className='case-wrapper'>
                <>
                    <div className='case-study-top-wrapper'>
                        <div className='case-study-title-wrapper'>
                            <h1 className="header-title" style={{ fontSize: '5.5rem' }}>
                                {changeIrish ? <strong>Acmhainní Foghlama</strong> : <strong>Learning Resources</strong>}
                            </h1>
                        </div>
                        <div className="title-img-wrapper">
                            <img src="/images/case-studies/historic.webp" alt="" style={{
                                opacity: 0.5, width: "auto",
                                height: "600px", margin: 10
                            }} />
                        </div>
                    </div>
                    <Container fixed>

                        {!changeIrish ?
                            <>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <span className="dropcap">T</span>
                                        <p className='para-style'>his section of the MACMORRIS website is made up of a number of rich learning resources, which are aimed at providing historical context, cultural context, guides on how to use the visualisations available on the site, and activity sheets aimed at both second-level and third-level classrooms.
                                        </p>

                                        <p className='para-style'><Link to='/historical-overview'>Historical Overview</Link> provides a starting point for learning about the dramatic period of conflict, change, and innovation which transformed Ireland. It gives context to the period covered by our Deep Map, including an overview of the Desmond wars, the Munster Plantation, the Battle of Kinsale, and the literary responses to these events.
                                        </p>
                                    </div>
                                </div>


                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>There are also four resources aimed at providing introductions, overviews, and snapshots of cultural activity in Ireland. There are two resources that engage with Gaelic literature: <Link to='/what-is-bardic-poetry'> What is Bardic Poetry </Link> gives an introduction to the literary landscape of Early Modern Ireland and the poetry composed by the professional caste of poets (or filidh) trained in the bardic schools. It considers the role of the poet, and asks who patronised these poets, before looking at what these poets wrote about, alongside the metre and register they wrote in.
                                            <Link to='/using-gaelic-sources'> Using Gaelic Sources </Link>gives an overview of the substantial body of Irish-language materials available, and how these materials can rebalance our understanding of Ireland at a formative moment of transition. It gives examples of the types of poetry, prose, and annalistic sources available and highlights resources, such as
                                            <a href='https://xn--lamh-bpa.org/' target='blank noopener'> Léamh.org</a>, that can help users learn early modern Irish.
                                        </p>
                                    </div>
                                </div>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>The other two resources aimed at examining cultural activity in Ireland explore literature in the English language.
                                            The case study about <Link to='/spenser-in-munster'>Edmund Spenser</Link> charts his connection to Ireland, and considers his importance within the literary and political landscape of Munster, while the resource
                                            on <Link to='/early-modern-drama'>Early Modern Irish Drama</Link> gives an overview of dramatic productions in Ireland from John Bale’s ecclesiastical plays, to the Werburgh Street, and Smock Alley theatre productions.
                                        </p>
                                    </div>
                                </div>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>The final type of resources are the how-to guides which provide an introduction to the visualisations available on the site.
                                            The <Link to='/deep-mapping'>Deep Mapping </Link>resource introduces the concept of deep mapping, shows how it can be used to explore cultural activity in Munster, and provides a video tutorial on how to navigate the map.
                                            The <Link to='/networking'>Networks </Link>resource provides an introduction to network visualisation, showcases how visualising connections can bring lesser-known actors to the fore, and provides a video tutorial on how to navigate the network interface.
                                        </p>
                                    </div>

                                </div>


                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>The activity sheets (which can be downloaded below) draw on and are connected to the other resources and are intended to work in tandem with them. These activity sheets were designed by Emma Cooling in collaboration with the other members of the MACMORRIS team. There are four activity sheets in total: three aimed at third-level English literature and Irish literature classrooms, and one designed for second-level students. Of the three third-level activity sheets, the Edmund Spenser document and the eco-critical approaches to literature document are pitched at English Literature classrooms, while the Bardic Poetry document is designed for Irish language students. In terms of the second-level activity sheet, it is intended that the below activities could be used in a mix-and-match fashion alongside a chronological curriculum of the period for Transition Year students, but Leaving Cert History Students may also find these exercises useful as inspiration for their History Research Project, and Junior Cert History Students will find the activity on the Munster Plantation useful.
                                        </p>
                                    </div>
                                </div>
                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>
                                            <a
                                                href="/downloadFiles/edmund.pdf"
                                                download="Edmund Spenser Activity Sheet"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Edmund Spenser Activity Sheet
                                            </a>
                                        </p>
                                        <p className='para-style'>

                                            <a

                                                href="/downloadFiles/eco.pdf"
                                                download="Eco Criticism Activity Sheet"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Eco-criticism Activity Sheet
                                            </a>
                                        </p>
                                        {/* <p className='para-style'>

                                            <a

                                                href="/downloadFiles/download.pdf"
                                                download="Example-PDF-document"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Bardic Poetry Activity Sheet
                                            </a>
                                        </p> */}
                                        <p className='para-style'>

                                            <a

                                                href="/downloadFiles/secondary.pdf"
                                                download="Second Level Activity Sheet"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Second-Level Activity Sheet (English)
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </>
                            :
                            <>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <span className="dropcap">S</span>
                                        <p className='para-style'>a chuid seo de shuíomh MACMORRIS, cuireann muid acmhainní foghlama ar fáil le comhthéacs stairiúil agus cultúrtha a thabhairt, chomh maith le treoracha d'úsáid an tsuímh. Cuireann muid bileoga cleachtaidh ar fáil chomh maith is féidir a úsáid sa seomra ranga ag leibhéal na meánscoile agus leibhéal na hollscoile.
                                        </p>
                                        <p className='para-style'> Is pointe tosaigh maith é an <Link to='/historical-overview'>Léargas Ginearálta Staire</Link> má tá tú ag iarraidh tuiscint níos fearr a fháil ar an tréimhse achrannach seo i stair na hÉireann. Tugtar comhthéacs ar na blianta a bhaineann le Léarscáil Dhomhain MACMORRIS (1560–1607), lena n-áirítear an tÉirí Amach i nDeasumhain, Plandáil na Mumhan, Cath Chionn tSáile, agus tugtar bunfhoinsí eolais a bhaineann leis na heachtraí seo i dteangacha éagsúla. </p>
                                        <p className='para-style'> Tá ceithre acmhainn eile a bhfuil sé i gceist acu léargas agus buneolas a chur ar fáil i dtaca le cúrsaí cultúrtha na hÉireann. Péire acu sin faoi litríocht na nGael: Tugann <Link to='/what-is-bardic-poetry'>"Cad atá i gceist le Filíocht na Scol"</Link> léargas ar thraidisiún liteartha na hÉireann agus ar an sórt filíochta a bhí á cumadh ag na filí gairmiúla a fuair oiliúint i scoileanna filíochta na Gaeilge. Caitear súil ar ról na bhfilí sa tsochaí agus a bpátrúin, chomh maith le heolas ar ábhar na ndánta agus ar mheadarachtaí. Tugann <Link to='/using-gaelic-sources'>"Foinsí Gaeilge"</Link> eolas ar na bunfhoinsí eolais ar fad atá ar fáil dúinn i nGaeilge, agus an tábhacht a bhaineann le húsáid na bhfoinsí seo taobh le foinsí i dteangacha eile. Tugtar samplaí d'fhilíocht, prós, annála agus tugtar aird ar acmhainní foghlama eile, cosúil le
                                            <a href='https://xn--lamh-bpa.org/' target='blank noopener'> Léamh.org</a>, do dhaoine atá ag iarraidh an Nua-Ghaeilge Mhoch a fhoghlaim. </p>
                                        <p className='para-style'> Díríonn an péire eile ar chultúr an Bhéarla in Éirinn. Tá cás-staidéar ar <Link to='/spenser-in-munster'>Edmund Spenser</Link>, an ceangal a bhí aige le hÉirinn, agus an tábhacht a bhaineann leis i gcúrsaí liteartha agus polaitíochta Chúige Mumhan. Tugann <Link to='/early-modern-drama'>"Drámaíocht sa Nua-Aois Luath"</Link> léargas dúinn ar na drámaí a cumadh agus a léiríodh in Éirinn sa 17ú haois. </p>
                                        <p className='para-style'> Tugann na hacmhainní eile treoracha ar úsáid an tsuímh, go háirithe conas leas a bhaint as na léirshamhluithe atá ar fáil anseo. San achmhainn <Link to='/deep-mapping'>"Cruthú Léarscáil Dhomhain"</Link>, léiríonn muid cad atá i gceist le léarscáil dhomhain, agus taispeánann muid conas is féidir í a úsáid le tuiscint níos fearr a fháil ar
                                            chúrsaí cultúrtha i gCúige Mumhan sa Nua-Aois Luath.  Is féidir léargas a fháil ar léirshamhlú
                                            <Link to='/networking'> líonraí </Link>sa chás-staidéar eile, agus conas is féidir an comhéadan a úsáid le nascanna a fheiceáil idir dhaoine éagsúla agus a saothar. </p>
                                        <p className='para-style'>Cuireann muid bileoga cleachtaidh ar fáil is féidir a íoslódáil thíos. Chruthaigh Emma Cooling na bileoga seo le cuidiú ó bhaill eile d'fhoireann MACMORRIS. Tá ceithre cinn ar an iomlán: trí cinn acu ar litríocht an Bhéarla agus litríocht na Gaeilge dírithe ar mhic léinn ag an tríú leibhéal, agus ceann amháin do dhaltaí meánscoile. As na cinn do mhic léinn ag an tríú leibhéal, tá ceann ar Edmund Spenser agus ceann ar an Léirmheastóireacht Éiceolaíoch atá oiriúnach do mhic léinn litríocht an Bhéarla, agus ceann eile ar Fhilíocht na Scol atá oiriúnach do mhic léinn litríocht na Gaeilge. Bhí muid ag cuimhniú ar dhaltaí Idirbhliana nuair a chruthaigh muid an bhileog chleachtaidh do mheánscoileanna, ach bheadh sé oiriúnach chomh maith do dhaltaí na hArdteiste dá dtionscadal taighde, má tá suim acu sa tréimhse seo, agus d'fhéadfadh daltaí an Teastais Shóisearaigh leas a bhaint as an chuid den bhileog a bhaineann le Plandáil na Mumhan.</p>

                                    </div>

                                    <div className='case-flex-column'>
                                        <div className='para-flex'>
                                            <p className='para-style'>
                                                <a
                                                    href="/downloadFiles/edmund.pdf"
                                                    download="Edmund Spenser Activity Sheet"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Edmund Spenser Activity Sheet
                                                </a>
                                            </p>
                                            <p className='para-style'>

                                                <a

                                                    href="/downloadFiles/eco.pdf"
                                                    download="Eco Criticism Activity Sheet"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Eco-criticism Activity Sheet
                                                </a>
                                            </p>
                                            {/* <p className='para-style'>

                                            <a

                                                href="/downloadFiles/download.pdf"
                                                download="Example-PDF-document"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Bardic Poetry Activity Sheet
                                            </a>
                                        </p> */}
                                            <p className='para-style'>

                                                <a

                                                    href="/downloadFiles/gaelic_secondary.pdf"
                                                    download="Second Level Activity Sheet"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Second-Level Activity Sheet (Gaelic)
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </>

                        }

                    </Container>
                </>
            </div>
            <Footer />

        </>
    );
}

export default CaseLearningResources;
