import React, { useEffect, useRef, useState } from 'react';
import './home.scss'
import Container from "@mui/material/Container";
import Header from './Header';
import ScrollToTop from '../Home/ScrollToTop';
import Footer from '../Home/Sponsors';
import { Link } from 'react-router-dom';
import AboutIrish from './AboutIrish';

function About({ changeIrish, setChangeIrish }) {

    return (
        <>
            <ScrollToTop />

            <Header changeIrish={changeIrish} setChangeIrish={setChangeIrish} />

            <div className='case-wrapper'>
                {!changeIrish ?
                    <>

                        <div className='case-study-top-wrapper'>
                            <div className='case-study-title-wrapper'>
                                <h1 className="header-title"><strong>About MACMORRIS</strong></h1>
                            </div>
                            <div className="title-img-wrapper">
                                <img src="/images/banner/search1.webp" alt="" style={{
                                    opacity: 0.5, width: "auto",
                                    height: "580px", margin: 10
                                }} />
                            </div>
                        </div>
                        <Container fixed>
                            <>
                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <span className="dropcap">M</span>
                                        <p className='para-style'>ACMORRIS is a four-year (2019-2023) <a href='https://research.ie/'>Irish Research Council</a> funded project which recovers the cultural vibrancy and complexity of Ireland's transformative years between Henry VIII's assumption of the kingship of Ireland in 1541 and the Restoration in 1660. </p>

                                        <p className='para-style'>It takes its title from Captain Macmorris, the stage-Irishman in Shakespeare's Henry V (a name itself borrowed from two key players in late 16th-century Munster). It seizes upon Macmorris's impassioned questions, ‘What ish my nation? Who talks of my nation?', and looks for answers by bringing all the voices from the period together to deliver their mosaic response. </p>

                                        <p className='para-style'>Early modern Ireland was a site of extraordinary literary vibrancy. Bardic schools flourished, educating the filidh (hereditary professional poets) whose centrality to the lordships of Gaelic and Gaelicised Ireland anathematised them in the eyes of the incoming Tudor administrators. Neo-Latin scholarship took on the energies of the Counter-Reformation, not just in theological writings but in histories like <Link to='/profile/m2140'>Philip O'Sullivan Beare's </Link> Historiae Catholicae Iberniae compendium; it powered epics like <Link to='/profile/m3476'>Dermot O'Meara's</Link>  Ormonius. At the same time, the English of Ireland had its own vitality, manifest in <Link to='/profile/m1740'>Richard Stanihurst's</Link>   contribution to Holinshed's Chronicles and in his highly experimental translation, Thee first foure bookes of Virgil his Aeneis. </p>

                                        <p className='para-style'>By the 1570s, the cultural complexity already manifest in this cross-fertilisation of the Gaelic and Old English communities was further complicated by the irruption of soldiers, administrators, and settlers – the agents of the Elizabethan conquest – into Ireland. One of the striking features of the conquest is the number of writers in its vanguard. Pamphleteers, propagandists, and polemicists like <Link to='/profile/m1328'>John Derricke,</Link> <Link to='/profile/m1725'>Barnaby Rich,</Link> and <Link to='/profile/m3513'>Thomas Churchyard</Link> filtered Ireland through a lens of lurid sensation for their compatriots at home. Scientific improvers like <Link to='/profile/m1446'>Barnabe Googe,</Link> <Link to='/profile/m1431'>Robert Payne,</Link> and <Link to='/profile/m3515'>Gervase Markham</Link> drew up their blueprints for colonial husbandry. Soldiers, administrators, and prospectors filed a stream of ‘reports', ‘platts' and ‘views'. Sir <Link to='/profile/m1846'>George Carew</Link> took time out from the conquest to translate the first 16 books of Alonso de Ercilla y Zuñiga's epic of the conquest of Chile, La Araucana. <Link to='/profile/m1667'>Sir John Harington</Link> translated Ariosto's Orlando Furioso between two spells of duty in Ireland. And, most famously, in The Faerie Queene, <Link to='/profile/m1506'>Edmund Spenser</Link> created a ‘Faerie-lond' out of an Ireland spun into strangeness by allegory. </p>

                                        <p className='para-style'>Thus, the complexity of early modern Ireland – the mix of languages, populations and traditions – means that the study of the period should itself be correspondingly interdisciplinary and multilingual. MACMORRIS provides a research engine for such an engagement. It offers materials for a unified cultural history of early modern Ireland, one that captures the range, richness, and polyvocality of cultures intersecting and undergoing profound transformation. </p>
                                    </div>
                                </div>



                                <div className='case-flex-column'>
                                    <p className='para-style'><strong>MACMORRIS has two main components:</strong></p>
                                    <div className='para-flex'>
                                        <p className='para-style'>
                                            It is a dataset containing biographical and bibliographical metadata of people active in Ireland between 1541 and 1660. Along with soldiers, administrators, merchants, clergy etc., this dataset incorporates cultural producers of all kinds, across languages: poets, pamphleteers, chroniclers, theologians; translators; inventors, and innovators; patrons; and members of the aos ealadhan (learned class). This metadata can be searched and viewed through our search screen and can be visualised using our network interface.
                                        </p>
                                        <p className='para-style'>
                                            Focusing on Munster in the period 1569-1607, it explores how patterns of contiguity, confluence, and crossover make Ireland, all at once, a place of deeply embedded cultural traditions, a site of vigorous exchange and, ultimately, the locus of dramatic change over time. </p>

                                    </div>
                                </div>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>Embedded in both components is a commitment to drawing attention back to the Gaelic voices so often sidelined in anglophone accounts of early modern Ireland. In total, 44 bardic poems have been included in the project – we have produced full critical editions and translations for 5 bardic poems; excerpts from 6 poems have been thoroughly annotated for students of Early Modern Irish and are being made available on the <a href='https://léamh.org/'>Léamh.org</a> website; of the 39 excerpts from bardic poems which made it onto our <Link to='/map'>Deep Map</Link>, only 14 had been previously comprehensively edited and translated. The remaining 25 were worked on by the MACMORRIS team, ranging from minor editing and translation to complete critical editions. </p>
                                        <p className='para-style'>Project Reference: IRCLA/2019/116 </p>
                                    </div>
                                </div>


                                <p className='para-style'><strong>The Dataset</strong> </p>


                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>For MACMORRIS to capture the full complexity of Ireland from 1541 to 1660, it had to assemble an inclusive cast list. Its biographical database builds out from the <a href='https://www.dib.ie//'>Dictionary of Irish Biography</a>(DIB) which the Royal Irish Academy generously shared with the project. For the period 1541-1660, that gave us 1,068 biographies. The DIB is, by definition, a work of traditional historiography; those who gain entry are, predominantly, politicians, soldiers, administrators, canonical writers, clergymen. They are overwhelmingly male: just 38 women (3.5%) then had their own entry. (That underrepresentation is representative: the comparable figure in The Oxford Dictionary of National Biography is 5.4%). </p>
                                        <div className='image-wrapper'>
                                            <img src="/images/about/fig2en.webp" alt="" style={{ width: "auto", height: "540px", margin: 10 }} />
                                            <figcaption> Dictionary of Irish Biography Landing Page. </figcaption>
                                        </div>

                                        <p className='para-style'>To make the cast list more inclusive, we augmented the personae list by reading through the DIB to extract every figure mentioned within each biography. During this process, we assigned an ID for every new person mentioned, provided that they could be accurately disambiguated, be assigned a floruit and a role / occupation, and had lived in or be linked to Ireland. During this process we also marked up a connection type – ‘kinship', ‘ally', ‘enmity' – and a time length for their connection to Ireland, drawing on the contextual information within the biographies (For a list of these role / occupations / connection types, see our taxonomy document).  We then went beyond DIB in search of ‘cultural actors', i.e. both those who produce culture (primary cultural actors) and those who make the weather for cultural practitioners (secondary cultural actors). </p>

                                        <p className='para-style'>Our first port of call for expanding the list of cultural actors was the <a href='https://bardic.celt.dias.ie/'>Irish Bardic Poetry Database</a> (IBPD) to which the Dublin Institute of Advanced Studies kindly gave us access. Of IBPD's 720 figures, only 86 (19 poets and 67 patrons) had their own biographies in DIB. Of the actors brought in from the IBPD, 105 (14.5%) were women; of these, 32 were the patrons of poets, making up 12% of the 269 patrons identified. </p>

                                        <div className='image-wrapper'>
                                            <img src="/images/about/fig3.webp" alt="" style={{ width: "auto", height: "500px", margin: 10 }} />
                                            <figcaption> Bardic Poetry Database Landing Page. </figcaption>

                                        </div>


                                        <p className='para-style'>We further diversified the cast list by bringing in figures from the State Papers, Fiants, the Annals of the Four Masters, early printed books, and old county historical and archaeological journals. Members of hereditary learned families – poets, legal scholars, historians, physicians, and musicians – came into view, as did poets working outside the bardic tradition. For example, in building entries for the liaig, the hereditary medical scholars, we also came up patron-patients like the two Caomhánach sisters whom <Link to='/profile/m3586'>Corc Óg Ó Cadhla</Link> treated for menstrual disorders while working on Bernard de Gordon's Lilium medicinae in <Link to='/profile/m3572'>Brian Caomhánach's</Link>  castle. </p>

                                        <p className='para-style'>Our search for cultural players extended to writers who spent part of their career in Ireland, such as <Link to='/profile/m3760'>Maurice Kyffin</Link>, comptroller of the musters in Ireland, a sonneteer in English, and a significant writer of poetry and verse in Welsh; and <Link to='/profile/m3519'>Parr Lane</Link> whose ‘Newes from the holy ile' twitted John Rothe in pseudo-Spenserian verse. Continental writers who spent time in Ireland make it in too, like <Link to='/profile/m1137'>Captain Francisco de Cuéllar</Link> whose Carta de uno que fué en la Armada provides one of the most vivid and sympathetic accounts of 1580s' Ireland. </p>

                                        <p className='para-style'>The database expanded to include members of other learned classes, religious and secular. Among the clergy, we paid particular attention to chaplains – King <Link to='/profile/m1288'>Sebastian of Portugal's</Link> confessor <Link to='/profile/m1546'>Redmond Gallagher</Link>, or <Link to='/profile/m1616'>Christopher Goodman</Link> and <Link to='/profile/m5661'>Nicholas Keenan</Link>, chaplains to <Link to='/profile/m1394'>Sir Henry Sidney</Link> – precisely because they often functioned as linchpins of religious, political, and intellectual networks. The cultural importance of educators led us to include school-keepers like <Link to='/profile/m1757'>Alexander Lynch</Link> in Galway and <Link to='/profile/m5718'>Mychhill Hussea</Link> in Dingle, as well as tutors to the mighty, such <Link to='/profile/m1818'>Aodh Mac Cathmhaoil</Link>, <Link to='/profile/m5657'>Fr Peter Nangle</Link>, <Link to='/profile/m4363'>Hugo O'Galuchor</Link> and an Englishman called <Link to='/profile/m1616'>Bennett</Link>, all tutors to Aodh Ó Néill's sons. </p>

                                        <div className='image-wrapper'>
                                            <img src="/images/about/fig4.webp" alt="" style={{ width: "auto", height: "580px", margin: 10 }} />

                                            <figcaption>  TCD 1209/44, “Map of the barony of Imokilly in the County of Cork”, Hardiman Atlas. Thanks to the Board of Trinity College Dublin.  </figcaption>
                                        </div>


                                        <p className='para-style'>Of course, the cultural convulsions that Ireland underwent in early modernity ultimately
                                            owed less to primary cultural actors than to ‘secondary cultural actors' – those who
                                            created the conditions (enabling or imperilling) in which the primary cultural actors
                                            operated. These include practitioners in the arts of war such as 37 mapmakers (<Link
                                                to='/profile/m4308'>Angelus Angelutius</Link> sketching Smerwick; or <Link to='/profile/m4329'>Laurence Nowell</Link> who depicts himself being barked at by the occupational hazard of all cartographers, a cross dog) and 21 military engineers (surveyors like <Link to=' /profile/m5642'>Thomas Raven</Link>, preparing the way for the Plantation of Ulster; or men like Captain <Link to='/profile/m1754'>James Archer</Link> ‘an Irishman and a papist – but a brilliant engineer'). Along with Irish, English, Spanish, and Italian military commanders, we capture some of the backroom clerks of conflict and colonisation. While we know great deal of the English bureaucracy of war – secretaries like <Link to='/profile/m1423'>Edward Waterhouse</Link> in whose arms the <Link to='/profile/m1618'>first Earl of Essex</Link> expired; or pursuivants like <Link to='/profile/m5689'>Nicholas Narbon</Link>, Ulster King at Arms – the administration of Gaelic lordships can be brought out of the shadows by profiling people like <Link to='/profile/m4354'>Nicholas fitz William</Link>, receiver-general of the <Link to='/profile/m1530'>Earl of Desmond</Link>, or <Link to='/profile/m4355'>William McCrodan</Link>, <Link to='/profile/m1704'>Aodh Ó Néill's</Link> brehon. </p>

                                        <p className='para-style'>All combined, this results in an overarching dataset of more than 4500 people, along with the more than 12,500 ways in which these people were connected to each other (see <Link to='/networking'>“Networks”</Link> for how we visualised these connections). These people are a mix of sixteen different nationalities and produce literature in a total of ten different languages, capturing the complex assemblage of early modern Ireland. </p>

                                        <p className='para-style'>Alongside the database of cultural actors, we have also captured the range of works written in, from, or about Ireland in the period, in MACMORRIS's bibliography of primary works. This hand-curated dataset is drawn from the ESTC, EEBO, BPD, Clóliosta, and the bibliographic work of McClintock Dix and Sweeney. It comprises works written by any Irish actor, by an actor while they were in Ireland, works substantially about Ireland, or printed in Ireland, or written by an actor before or after their time in Ireland. While not definitive, it provides the largest multilingual bibliography of primary sources related to Ireland for this period. </p>

                                    </div>
                                </div>

                                <p className='para-style'><strong>The Deep Map</strong> </p>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <div className='image-wrapper'>
                                            <img src="/images/about/fig5.webp" alt="" style={{ width: "auto", height: "580px", margin: 10 }} />

                                            <figcaption> MACMORRIS Deep Map of Munster. </figcaption>
                                        </div>

                                        <p className='para-style'>The deep map is a spatially ordered digital anthology which offers curated extracts from a theatre of crisis, designed to capture the heterogeneity and contending perspectives of a Gaelic world under threat of erasure (though it didn't know that then) and a new order (colonial and proto-capitalist) in formation. Our textual selections are guided by a decolonising agenda that seeks to make available sources (particularly in Irish) whose underrepresentation has skewed our understanding of the period. In doing so, we were guided by the extracts' literary quality, historical significance – or, simply, their quirkiness. Collectively, they represent the widest possible range of viewpoints, genres, and languages from a world undergoing enforced transition. In total, there are 197 extracts, each assigned to one of six categories: patronage and lordship, cultural production, strategic or political, conflict and violence, music and entertainment, blue-eco-maritime sites, green-eco-sites. (For more details see <Link to='/deep-mapping' >Deep Mapping</Link>). Like all deep maps, this visualisation is not authoritative. It involves negotiation over who and what is represented, and above all it is aimed at highlighting cultural intersections in a time of conflict while encouraging exploration and discovery. </p>
                                    </div>

                                </div>

                                <p className='para-style'><strong>Technical Information: </strong> </p>
                                <p className='para-style'><strong>Data Access</strong> </p>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>In addition to data being available to view through the web interface, users can download raw data via the search system’s in-built export tools. Search results may be exported as a .csv file, which can then be opened in software such as Excel, or a standard text editor. </p>
                                    </div>
                                </div>

                                <p className='para-style'><strong>Citing the data</strong> </p>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>Our data is licensed under a <a href='https://creativecommons.org/licenses/by-nc/4.0/'> Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0).</a>
                                        </p>
                                        <p className='para-style'>
                                            The MACMORRIS database is made up of a number of separate components. The database was designed and developed at the Arts and Humanities Institute, Maynooth University. </p>

                                    </div>
                                </div>

                                <p className='para-style'><strong>Database System Overview</strong> </p>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>

                                        <p className='para-style'>
                                            The web application was built using the Ruby on Rails framework, a web development tool known for its efficiency and scalability. The database was built using postgreSQL, an open-source relational database management system, and it is combined with the Rails framework to oversee and maintain the backend.
                                        </p>
                                        <p className='para-style'>
                                            The database’s public user interface was built using React JS. For the Map UI we also used Mapbox, and deck.gl (a powerful and flexible library for data visualisation on web maps), employing a layered approach to Data visualisation. For the Network UI, we employed react-force-graph, a React library tailored for building compelling 2D and 3D network visualisations. To achieve a high level of responsiveness and interactivity, both the MAP UI and network visualisations leverage the power of Graphics Processing Unit (GPU). Using the computational capabilities of GPUs, these visualisations can efficiently handle complex rendering tasks, and smooth animations.
                                        </p>

                                        <p className='para-style'>For more detailed technical documentation, see our <a href='https://rupavathis.github.io/' target='blank noopener'>blog</a>.</p>
                                    </div>
                                </div>

                                <p className='para-style'><strong>Affiliates</strong> </p>
                                <div className='case-flex-column'>
                                    <div className='para-flex'>

                                        <p className='para-style'>This project was only possible due to the collaboration and support of colleagues, both institutional and individual. </p>

                                    </div>
                                </div>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'><a href='https://bardic.celt.dias.ie/'>Dublin Institute of Advanced Studies</a>: Thanks to DIAS for sharing with us the backend metadata
                                            of the Bardic Poetry Database from which we established our list of Bardic poems, poets, patron
                                            and patronage connections.</p>
                                        <p className='para-style'>
                                            <a href='https://www.dib.ie/'>
                                                Dictionary of Irish Biography </a>: Thanks to the DIB for sharing with the backend .xml files of the DIB,
                                            from which we were able to extract the embedded network connections from.
                                        </p>
                                        <p className='para-style'>
                                            <a href='https://www.Léamh.org/'>
                                                Léamh</a>: Thanks to Léamh for collaborating with us on producing, alongside hosting, a number of quatrains
                                            from different bardic poems from our Munster case study.
                                        </p>
                                        <p className='para-style'>
                                            <a href='https://www.maynoothuniversity.ie/experiential-learning/students/research'>
                                                Maynooth SPUR Programme</a>: Thanks to the SPUR programme for funding and facilitating three different
                                            student projects affiliated with MACMORRIS between 2020 and 2023. Thanks to Éabha Puirséil for her work
                                            on the MACMORRIS bibliography and one of the texts available on Léamh. Thanks to Conor Killian for
                                            his work on data cleaning and data entry of biographical metadata and network connection metadata.
                                            Thanks to Emma Cooling for her work on the MACMORRIS learning guides
                                        </p>
                                        <p className='para-style'>
                                            <a href='https://maynoothuniversity.ie/external-relations'>
                                                Maynooth Communications Office</a>: Thanks to Daniel Balteanu and the communications office for collaborating with us on producing the videos for the site. Thanks also to Alan Waldron for providing the voiceover for the tutorial video.
                                        </p>
                                    </div>
                                </div>

                                <p className='para-style'><strong>
                                    Individual affiliates</strong> </p>
                                <ol className='ordered-affiliates'>
                                    <li><a href='https://englishcomplit.unc.edu/faculty-directory/david-john-baker/'>David Baker (English and Comparative Literature, UNC Chapel Hill)</a></li>
                                    <li><a href='https://www.universityofgalway.ie/our-research/people/english-and-creative-arts/marielouisecoolahan/'>Marie-Louise Coolahan (English and Creative Arts, NUI Galway)</a></li>
                                    <li><a href='https://history.uconn.edu/faculty-by-name/brendan-kane/'>Brendan Kane (History, UCONN)</a></li>
                                    <li><a href='https://www.maynoothuniversity.ie/people/stephen-oneill'>Stephen O'Neill (English, Maynooth)</a></li>
                                    <li><a href='https://www.maynoothuniversity.ie/people/kevin-tracey'>Kevin Tracey (English, Maynooth)</a></li>
                                    <li><a href='https://www.gla.ac.uk/schools/critical/staff/willymaley/'>Willy Maley (Critical Studies, University of Glasgow)</a></li>
                                    <li><a href='https://pure.royalholloway.ac.uk/en/persons/deana-rankin'>Deana Rankin (English and Drama, Royal Holloway)</a></li>
                                    <li><a href='https://people.ucd.ie/marc.caball'>Marc Caball (History, UCD)</a></li>
                                    <li><a href='https://www.hope.ac.uk/si/dr-ruth-canning.html'>Ruth Canning (History and Politics, Liverpool Hope)</a></li>
                                    <li><a href='https://people.ucd.ie/jane.grogan'>Jane Grogan (English, Drama and Film, UCD)</a></li>
                                    <li><a href='https://profiles.sussex.ac.uk/p131314-andrew-hadfield/about'>Andrew Hadfield (English, Sussex)</a></li>
                                    <li><a href='https://people.tcd.ie/Profile?Username=mhoyne'>Mícheál Hoyne (Gaeilge, TCD)</a></li>
                                    <li><a href='https://mooreinstitute.ie/about-us/core-staff/'>David Kelly (Digital Humanities Manager, NUI Galway)</a></li>
                                    <li><a href='https://irishstudies.nd.edu/scholars/faculty-fellows/sarah-mckibben/'>Sarah McKibben (Irish Studies, Notre Dame)</a></li>
                                    <li><a href='https://www.maynoothuniversity.ie/people/eoghan-raghallaigh'>Eoghan Ó Raghallaigh (Gaeilge, Maigh Nuad)</a></li>
                                    <li><a href='https://pure.au.dk/portal/en/persons/isabelle-torrance(c9ba2efc-ef38-4bf2-8061-ae5f33b8cb0a).html'>Isabelle Torrance (English, Aarhus)</a></li>
                                </ol>
                                <p className='para-style'><strong>
                                    Acknowledgements
                                </strong></p>

                                <div className='case-flex-column'>
                                    <div className='para-flex'>
                                        <p className='para-style'>
                                            We would like to thank the following institutions for their kind permission to use images from their collections:
                                        </p>
                                        <ol className='ordered-affiliates'>
                                            <li><a href='https://www.bl.uk/'>British Library</a></li>
                                            <li><a href='https://marshlibrary.ie'>Marsh's Library</a></li>
                                            <li><a href='https://www.nli.ie/'>National Library of Ireland</a></li>
                                            <li><a href='https://www.rmg.co.uk/national-maritime-museum'>National Maritime Museum, London</a></li>
                                            <li><a href='https://www.tcd.ie/library/'>Trinity College Library</a></li>
                                            <li><a href='https://www.ria.ie/library'>Royal Irish Academy</a></li>
                                        </ol>
                                    </div>
                                </div>

                            </>





                        </Container>
                    </> : <AboutIrish />
                }
            </div >
            <Footer />
        </>
    );
}

export default About;
