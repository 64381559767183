/* eslint-disable no-use-before-define */
import React from 'react';
import { useEffect, useState, useMemo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { API_URL } from '../../../constants';
import Accordion from 'react-bootstrap/Accordion';


export default function SearchWorkNetwork({ workNetworkData, setWorkNetworkData, setSelectContent, setSearchID, setLoading
    , setWorkAdvancedSelectors }) {

    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState([]);

    const [patrons, setPatrons] = useState([]);
    const [selectedPatrons, setSelectedPatrons] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [selectedPrinters, setSelectedPrinters] = useState([]);
    const [booksellers, setBooksellers] = useState([]);
    const [selectedBooksellers, setSelectedBooksellers] = useState([]);
    const [publishers, setPublishers] = useState([]);
    const [selectedPublishers, setSelectedPublishers] = useState([]);

    useEffect(() => { fetchWorkTitles(); }, [])

    async function fetchWorkTitles() {
        const res = await fetch(`${API_URL}/authorship_types`);
        const peopleJson = await res.json();


        setAuthors(peopleJson[0].people)
        setPrinters(peopleJson[1].people)
        setPublishers(peopleJson[2].people)
        setBooksellers(peopleJson[3].people)
        setPatrons(peopleJson[4].people)
    }



    async function searchPeople() {
        setLoading(true)

        if (selectedAuthor.length == 0 && selectedPatrons.length == 0 && selectedPrinters.length == 0 && selectedPublishers.length == 0
            && selectedBooksellers.length == 0) {
            setWorkNetworkData([[], []])
            console.log("empty")
            setSelectContent(1)
            setSearchID(null)

            setLoading(false)
        }

        else {

            let url = `worksPeopleSearch?authors=${selectedAuthor ?? ""}&patrons=${selectedPatrons ?? ""}&printers=${selectedPrinters ?? ""}&publishers=${selectedPublishers ?? ""}&booksellers=${selectedBooksellers ?? ""}`;

            const worksRes = await fetch(`${API_URL}/${url}`);
            console.log('url test', `${API_URL}/${url}`)
            const worksJson = await worksRes.json();
            setWorkNetworkData(worksJson);
            console.log("workNetworkData in Search", worksJson)
            setSelectContent(1)
            setSearchID(null)

            setLoading(false)
            setWorkAdvancedSelectors({ selectedAuthor, selectedPatrons })
        }


    }

    return (
        <div className='advanced-search-list'>
            {[{
                "id": 1,
                "type": "Authors",
                "args": authors,
                "func": setSelectedAuthor
            },
            {
                "id": 2,
                "type": "Patrons",
                "args": patrons,
                "func": setSelectedPatrons
            },
            {
                "id": 3,
                "type": "Printers",
                "args": printers,
                "func": setSelectedPrinters
            },
            {
                "id": 4,
                "type": "Booksellers",
                "args": booksellers,
                "func": setSelectedBooksellers
            },
            {
                "id": 5,
                "type": "Publishers",
                "args": publishers,
                "func": setSelectedPublishers
            }].map((type) => (
                <Autocomplete
                    options={type.args}
                    getOptionLabel={(option) => ` ${option.macmorris_id} - ${option.display_name}` || ""}
                    id={type.id}
                    autoComplete
                    noOptionsText={`Loading ${type.type}...`}
                    includeInputInList
                    renderInput={(params, i) => (
                        <TextField {...params} key={i} label={`${type.type}`} variant="standard" />
                    )}
                    onChange={(event, value) => {
                        {
                            if (value === null) {
                                type.func([])
                            }

                            else type.func(value.id)
                        }
                    }}
                />))}
            <div className="button btnsecondary pagescroll" style={{ "marginTop": "30px" }}
                onClick={() => searchPeople()}>Search</div>
        </div>

    );
}
