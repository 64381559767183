import { Container } from '@mui/system';
import React from 'react';

function Title({author}) {

  return (
    <Container>
        {author}
    </Container>

  );
}

export default Title;
