import React, { useEffect, useRef, useState } from 'react';
import './home.scss'
import Container from "@mui/material/Container";
import Header from './Header';
import ScrollToTop from '../Home/ScrollToTop';
import Footer from '../Home/Sponsors';
import { Link } from 'react-router-dom';

function AboutIrish({ changeIrish, setChangeIrish }) {

    return (
        <>
            <div className='case-study-top-wrapper'>
                <div className='case-study-title-wrapper'>
                    <h1 className="header-title" style={{ fontSize: '5.5rem', marginTop: '10%' }}>
                        <strong>Fúinne</strong>
                    </h1>
                </div>
                <div className="title-img-wrapper">
                    <img src="/images/banner/search1.webp" alt="" style={{
                        opacity: 0.5, width: "auto",
                        height: "580px", margin: 10
                    }} />
                </div>

            </div>
            <Container fixed>
                {!changeIrish &&
                    <>
                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <span className="dropcap">M</span>
                                <p className='para-style'>Is tionscadal 4 bliana (2019-2023) é MACMORRIS, maoinithe ag an <a href='https://research.ie/'>Chomhairle um Thaighde in Éirinn</a>, a bhfuil sé i gceist aige solas a chaitheamh ar an chastacht agus ar an ilghnéitheacht chultúrtha in Éirinn le linn tréimhse lán le hathruithe ó rinne Anraí VIII rí Éireann de féin sa bhliain 1541 go dtí an Reistiréisean sa bhliain 1660. </p>
                                <p className='para-style'>Tá teideal an tionscadail tógtha againn ón dráma Henry V le Shakespeare agus an carachtar Captain Macmorris (ainm a thóg Shakespeare féin ó dhaoine mór le rá gníomhach i gCúige Mumhan ag deireadh an 16ú haois). Cuimhnítear ar na ceisteanna paiseanta a chuir Macmorris sa dráma sin: ‘What ish my nation? Who talks of my nation?', a spreagann muid le dul sa tóir ar fhreagraí, ag éisteacht leis na guthanna ar fad ón tréimhse thuasluaite le freagra ilghuthach a chur ós bhur gcomhair. </p>
                                <p className='para-style'>Bhí cúrsaí liteartha in Éirinn beo bríomhar sa Nua-Aois Luath. Bhí na scoileanna filíochta Gaeilge faoi bhláth, ag tabhairt oideachais do na filidh (filí oidhreachtúla gairmiúla) a raibh ról lárnach acu i sochaí na nGael, agus nach raibh todhchaí acu i saol na hÉireann de réir phleananna lucht riaracháin na dTúdarach. Bhí litríocht na Nua-Laidine faoi anáil an Fhrith-Reifirméisin, ní hamháin saothar diagachta, ach saothar staire chomh maith, cosúil le Historiae Catholicae Iberniae compendium le <Link to='/profile/m2140'>Pilib Ó Súilleabháin Béirre</Link>, agus eipicí ar nós Ormonius le <Link to='/profile/m3476'>Dermitius Meara. </Link>   Ag an am céanna, bhí beocht i gcúrsaí liteartha na Sasanach in Éirinn, léirithe ag saothar <Link to='/profile/m1740'>Richard Stanihurst</Link> in Holinshed's Chronicles agus a aistriúchán an-turgnamhach ar Thee first foure bookes of Virgil his Aeneis. </p>
                                <p className='para-style'>Fiú roimh na 1570daí, bhí castacht chultúrtha sa tír mar gheall ar an mheascán seanbhunaithe de na Gaeil agus na Sean-Ghaill, agus thug na riarthóirí, saighdiúirí, agus coilínigh nua as Sasana castacht chultúrtha bhreise go hÉirinn. Ar na gnéithe ba shuntasaí faoi na Sasanaigh seo a tháinig ag tús an choncais, tá an méid scríbhneoirí a bhí ina measc. Scríobh paimfléadaithe, bolscairí agus poileimiceoirí ar nós <Link to='/profile/m1328'>John Derricke,</Link> <Link to='/profile/m1725'>Barnaby Rich,</Link> agus <Link to='/profile/m3513'>Thomas Churchyard</Link> faoi Éirinn de réir mheon a gcomhthíreach sa bhaile i Sasana. Chuir feabhsaitheoirí eolaíocha ar nós <Link to='/profile/m1446'>Barnabe Googe,</Link> <Link to='/profile/m1431'>Robert Payne,</Link> agus <Link to='/profile/m3515'>Gervase Markham</Link> pleananna le chéile don riarachán coilíneach. Choinnigh saighdiúirí, riarthóirí agus taiscéalaithe cuntais i sraitheanna ‘reports', ‘platts' agus ‘views'. Agus é ar sos ó obair an choncais, d'aistrigh Sir <Link to='/profile/m1846'>George Carew</Link> an chéad 16 leabhar de La Araucana, eipic faoi choncas na Sile le Alonso de Ercilla y Zuñiga. D'aistrigh <Link to='/profile/m1667'>Sir John Harington</Link> Orlando Furioso le Ariosto idir dhá sheal dualgais in Éirinn. Agus, mar atá a fhios ag an saol, rinne <Link to='/profile/m1506'>Edmund Spenser</Link> tír aisteach choimhthíoch d'Éirinn in The Faerie Queene. </p>
                                <p className='para-style'> Mar go bhfuil cúrsaí cultúrtha in Éirinn chomh casta sin sa Nua-Aois Luath – meascán de theangacha, pobail agus traidisiúin – caithfidh muid tabhairt faoi staidéar ar an tréimhse seo ar bhealach iltheangach agus idirdhisciplíneach. Cuireann MACMORRIS inneall cuardaigh taighde ar fáil le cuidiú leis an staidéar seo. Soláthraíonn sé bunábhair thaighde ar stair chultúrtha na hÉireann sa Nua-Aois Luath a léiríonn réimse, saibhreas, agus ilghuthacht na gcultúr seo ag teacht le chéile agus ag athrú ó bhonn. </p>

                            </div>
                        </div>



                        <div className='case-flex-column'>
                            <p className='para-style'><strong>Tá dhá phríomhghé i gceist le MACMORRIS:</strong></p>
                            <div className='para-flex'>
                                <p className='para-style'>
                                    Is tacar sonraí é a bhfuil meiteashonraí beathaisnéiseacha agus bibleagrafaíocha ann de na daoine a bhí gníomhach in Éirinn idir na blianta 1541 agus 1660. Taobh le saighdiúirí, riarthóirí, ceannaithe, cléirigh, srl., tá gach sórt duine a raibh páirt acu i gcúrsaí cultúrtha, is cuma cén teanga a bhí acu: filí, paimfléadaithe, staraithe, diagairí; aistritheoirí; tionscnóirí agus nuálaithe; pátrúin; baill den aos ealadhan. Is féidir na meiteashonraí seo a fheiceáil agus a chuardach tríd an inneall cuardaigh agus is féidir sonraí a léirshamhlú trí úsáid a bhaint as an chomhéadan líonraí.
                                </p>
                                <p className='para-style'>
                                    Ag díriú ar Chúige Mumhan sa tréimhse 1569–1607, amharcann sé ar chomhtheagmhálacht, ar theacht le chéile, agus ar phobail ag meascadh, a léiríonn Éire mar thír lán le traidisiúin agus cultúir seanbhunaithe, lán le malartú traschultúrtha agus, ar deireadh thiar, lán le hathruithe ollmhóra thar na blianta. </p>

                            </div>
                        </div>

                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'> I gcroílár an tionscadail, tá an sprioc a bhí againn ón tús aird cheart a thabhairt ar ghuthanna na ndúchasach, a ndéantar neamhaird dóibh go rómhinic i dtraidisiún staireolaíochta an Bhéarla i dtaca le hÉirinn (agus coilíneachtaí eile) sa Nua-Aois Luath. Ar an iomlán, tá 44 dán ó thraidisiún filíochta na nGael mar chuid den tionscadal – tá eagráin chriticiúla agus aistriúcháin chuimsitheacha de 5 dhán curtha i gcló againn; tá sleachta anótáilte as 6 dhán á gcur suas ar <a href='https://léamh.org/'>Léamh.org</a> le cuidiú le mic léinn atá ag foghlaim na Nua-Ghaeilge Moiche; as na 39 sliocht a roghnaíodh don <Link to='/map'>Léarscáil Dhomhain</Link>,, ní raibh eagráin agus aistriúcháin chuimsitheacha curtha ar fáil díobh roimhe seo ach i gcás 14 acu. I gcás na 25 sliocht eile, bhí foireann MACMORRIS ag obair orthu, ag déanamh (mion)eagarthóireachta agus/nó aistriúcháin díobh a chur ar fáil don chéad uair. </p>
                                <p className='para-style'>Uimhir thagartha an tionscadail: IRCLA/2019/116 </p>

                            </div>
                        </div>


                        <p className='para-style'><strong>Tacar Sonraí</strong></p>


                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'>Ionas gur féidir le MACMORRIS an chastacht iomlán a bhaineann le hÉirinn idir na blianta 1541–1660 a léiriú, bhí orainn liosta ionchuimsitheach daoine a chur le chéile. Leis an bhunachar sonraí beathaisnéiseach seo a bhailiú, thosaigh muid le <a href='https://www.dib.ie//'>Díolaim Beathaisnéisí na hÉireann</a> (DIB), agus táimid buíoch d'Acadamh Ríoga na hÉireann as an bhunachar sonraí sin a roinnt linn. Bhí baint ag 1,068 iontráil bheathaisnéise leis an tréimhse 1541–1660. Faoi mar a thuigfeá, tá bunús DIB sa staireolaíocht thraidisiúnta; na daoine a mbeifí ag súil leo ann ná polaiteoirí, saighdiúirí, riarthóirí, scríbhneoirí canónta, cléirigh. Fir atá iontu den chuid is mó: as 1,068 iontráil, ní raibh ach 38 iontráil (3.5%) ag mná. (Tá neamhchothroime mar seo coitianta, féach ar The Oxford Dictionary of National Biography, mar shampla, áit nach mná iad ach i gcás 5.4% de na hiontrálacha). </p>

                                <div className='image-wrapper'>
                                    <img src="/images/about/fig2ie.webp" alt="" style={{ width: "auto", height: "540px", margin: 10 }} />
                                    <figcaption> Díolaim Beathaisnéisí na hÉireann. </figcaption>
                                </div>
                                <p className='para-style'>Le liosta níos ionchuimsithí a chur le chéile, leathnaigh muid é trí na hiontrálacha ar fad ar DIB a léamh le feiceáil cé na daoine a luaitear taobh istigh d'iontrálacha eile. Le linn an phróisis seo, thug muid uimhir aitheantais do gach duine a luaitear, a fhad go raibh muid in ann iad a imdhealú, floruit (ar a laghad) agus ról/gairm a thabhairt, agus nasc díreach a dhéanamh idir iad agus Éire. Rud eile a rinne muid le linn an phróisis seo ná an sórt caidrimh a bhí idir dhaoine a lua – 'ball teaghlaigh', 'cara/comhghuaillí', 'namhaid' – agus cé chomh fada is a mhair an nasc idir an duine agus Éire, ag tarraingt ón eolas comhthéacsúil a thugtar dúinn sna hiontrálacha beathaisnéise (más maith leat súil a chaitheamh ar liosta de na sóirt chaidrimh / rólanna / gairmeacha ar fad, féach ar ár gcáipéis tacsanomaíochta). Chuaigh muid sa tóir ansin ar dhaoine nach raibh in DIB a raibh baint acu le cúrsaí cultúrtha, .i. daoine a bhí ag obair in earnáil an chultúir (na príomhghníomhaithe cultúrtha) agus daoine a chothaigh an earnáil chultúrtha sin (gníomhaithe cultúrtha tánaisteacha). </p>

                                <p className='para-style'>Thug muid aghaidh ansin ar <a href='https://bardic.celt.dias.ie/'>Bhunachar Sonraí Fhilíocht na Scol</a>  (BPD), agus tá muid buíoch d'Institiúid Ard-Léinn Bhaile Átha Cliath as an bhunachar sonraí sin a roinnt linn. Bhí 720 duine in BDP, nach raibh iontráil ach ag 86 (19 file and 67 pátrún) acu siúd in DIB. As an 720 sin a tharraing muid isteach ó BPD, bhí 105 bean (14.5%) ina measc; as an 105 sin, thug 32 acu pátrúnacht ar an fhilíocht, ar ionann sin agus 12% den 269 pátrún a imdhealaíodh. </p>

                                <div className='image-wrapper'>
                                    <img src="/images/about/fig3.webp" alt="" style={{ width: "auto", height: "500px", margin: 10 }} />
                                    <figcaption> Bhunachar Sonraí Fhilíocht na Scol. </figcaption>

                                </div>

                                <p className='para-style'>Chuir muid leis an liosta arís trí dhaoine eile a tharraingt isteach ó Stát-Pháipéir, Fiants, na hAnnála leis na Ceithre Mháistrí, seanleabhair chlóite, agus seanirisleabhair staire. Tháinig baill de theaghlaigh léannta chun tosaigh – filí, breithimh, staraithe, seanchaidhe, lianna, ceoltóirí – chomh maith le baill eile den aos ealaíon a bhí ag obair ag leibhéil sóisialta éagsúla. Agus muid ag bailiú ainmneacha lianna, mar shampla, tháinig muid ar othair a thug pátrúnacht dóibh, cosúil leis an bheirt Chaomhánach, deirfiúracha ar chuir <Link to='/profile/m3586'>Corc Óg Ó Cadhla</Link>  cóir leighis orthu mar gheall ar neamhord míostraithe ag an am a raibh sé ag obair ar thras-scríbhinn de Lilium medicinae le Bernard de Gordon i gCaisleán <Link to='/profile/m3572'>Bhriain Chaomhánaigh</Link>. </p>

                                <p className='para-style'>Chuaigh muid sa tóir fosta ar údair a chaith tréimhse dá saoil ag obair in Éirinn, cosúil le <Link to='/profile/m3760'>Maurice Kyffin</Link>, ardreachtaire na mustar in Éirinn, a chum soinéid sa Bhéarla, agus arbh fhile suntasach na Breatnaise é; ansin bhí <Link to='/profile/m3519'>Parr Lane</Link> agus a dhán ‘Newes from the holy ile' ag magadh faoi John Rothe faoi anáil stíl véarsaíochta Spenser. Tá údair ón mhór-roinn ar an liosta chomh maith, cosúil leis an <Link to='/profile/m1137'>Captain Francisco de Cuéllar</Link> a scríobh Carta de uno que fué en la Armada a thugann ceann de na cuntais is soiléire agus is tuisceanaí dúinn d'Éirinn sna 1580daí. </p>

                                <p className='para-style'>Taobh leo sa bhunachar sonraí tá daoine léannta eile, daoine cráifeacha agus tuata. I measc na gcléireach, thug muid aird ar leith ar na séiplínigh – athair faoistine Rí King <Link to='/profile/m1288'>Sebastian na Portaingéile</Link> <Link to='/profile/m1546'>Réamonn Ó Gallchobhair</Link>  mar shampla, nó séiplínigh <Link to='/profile/m1394'>Sir Henry Sidney, </Link><Link to='/profile/m1616'>Christopher Goodman </Link>agus <Link to='/profile/m5661'>Nicholas Keenan</Link>– mar go raibh áit lárnach acu i measc lucht an chreidimh, lucht na polaitíochta, agus lucht na n-ealaíon. Thug tábhacht lucht an oideachais muid chomh fada le fir scoile cosúil le <Link to='/profile/m1757'>hAlasdar Ó Loingsigh</Link> i nGaillimh agus <Link to='/profile/m5718'>Mícheál Ó hEódhusa</Link> i nDaingean, chomh maith le hoidí chlann Aodha Uí Néill <Link to='/profile/m1818'>Aodh Mac Cathmhaoil</Link>, an tAthair <Link to='/profile/m5657'>Peter Nangle</Link>, <Link to='/profile/m4363'>Aodh Ó Gallchobhair</Link>, agus Sasanach dar sloinne <Link to='/profile/m1616'>Bennett</Link>. </p>
                                <div className='image-wrapper'>
                                    <img src="/images/about/fig4.webp" alt="" style={{ width: "auto", height: "580px", margin: 10 }} />

                                    <figcaption>  TCD 1209/44, “Map of the barony of Imokilly in the County of Cork”. Buíochas le Coláiste na Tríonóide, Baile Átha Cliath.  </figcaption>
                                </div>
                                <p className='para-style'>Dar ndóigh, i gcás an tsuaite chultúrtha in Éirinn sa Nua-Aois Luath, bhí tionchar níos mó ag na gníomhaithe tánaisteacha cultúrtha ná na príomhghníomhaithe cultúrtha – mar gurbh iadsan a chothaigh na coinníollacha (fabhracha nó neamhfhabhracha) ina raibh na príomhghníomhaithe ag obair. Ina measc sin, bhí lucht na cogaíochta cosúil le 37 cartagraí (leithéid (<Link to='/profile/m4308'>Angelus Angelutius</Link>, a dhear Ard na Caithne) agus 21 innealtóir míleata (suirbhéirí cosúil le <Link to='/profile/m5642'>Thomas Raven</Link>, ag ullmhú do Phlandáil Uladh;  agus Captain <Link to='/profile/m1754'>James Archer</Link> ‘an Irishman and a papist – but a brilliant engineer'). Taobh le ceannairí míleata na nÉireannach, na Sasanach, na Spáinneach agus na nIodálach ar an liosta, tá freastálaithe agus cléirigh ag feidhmiú sa chúlra den choinbhleacht agus den phróiseas coilíneachais. Cé go bhfuil go leor ar eolas againn faoi mhaorthas cogaíochta na Sasanach – rúnaithe cosúil le <Link to='/profile/m1423'>Edward Waterhouse</Link> a bhí le <Link to='/profile/m1618'>chéad Iarla Essex</Link>  nuair a d'éag sé; agus oifigigh ar nós <Link to='/profile/m5689'>Nicholas Narbon</Link>– is féidir córais riaracháin na dtiarnaí Éireannacha a thabhairt chun solais trí aird a tharraingt ar leithéid <Link to='/profile/m4354'>Nioclás Mac Uilliam</Link>, riarthóir de chuid <Link to='/profile/m1530'>Iarla Deasmhumhan</Link>, nó <Link to='/profile/m4355'>Uilliam Mac Rodáin</Link>, breitheamh <Link to='/profile/m1704'>Aodha Uí Néill</Link>. </p>

                                <p className='para-style'>San iomlán, tá 4500 duine sa tacar sonraí, chomh maith le 12,500 bealach is féidir nascanna a dhéanamh idir na daoine sin (más maith leat féachaint ar an dóigh a léirshamhlaíonn muid na nascanna sin, féach ar an leathanach <Link to='/networking'>“Líonraí”</Link>). Tá daoine de 16 náisiúntacht agus litríocht i ndeich dteanga éagsúla i dtacar sonraí MACMORRIS, a léiríonn meascán casta daoine in Éirinn sa Nua-Aois Luath. </p>

                                <p className='para-style'>Taobh leis na daoine a raibh baint acu le cúrsaí cultúrtha, bhí sé i gceist ag leabharliosta MACMORRIS aon rud a scríobhadh/a clódh/a cumadh in Éirinn (nó faoi Éirinn) le linn na tréimhse seo a thabhairt le chéile in aon áit amháin. Tharraing muid liostaí saothar as ESTC, EEBO, BPD, Clóliosta, agus as díolaim McClintock Dix and Sweeney. Tugtar san áireamh freisin saothar daoine a raibh baint acu le hÉirinn sa tréimshe seo, fiú saothar a cumadh taobh amuigh den tréimhse, nó taobh amuigh d'Éirinn féin. Cé nach bhfuil an liosta iomlán, is é an bhailiúchán is mó de bhunfhoinsí eolais a bhaineann le hÉirinn don tréimhse seo. </p>

                            </div>
                        </div>

                        <p className='para-style'><strong>An Léarscáil Dhomhain</strong></p>

                        <div className='case-flex-column'>
                            <div className='para-flex'>

                                <div className='image-wrapper'>
                                    <img src="/images/about/fig5.webp" alt="" style={{ width: "auto", height: "580px", margin: 10 }} />

                                    <figcaption> Léarscáil Dhomhain MACMORRIS de Chúige Mumhan. </figcaption>
                                </div>

                                <p className='para-style'>Séard atá i gceist leis an léarscáil dhomhain ná bailiúchán digiteach téacsanna breactha ar léirshamhlú thírdhreacha a bhfuil sé i gceist aici ilghnéitheacht agus peirspictíochtaí éagsúla na tíre a léiriú nuair a bhí sochaí na nGael ag titim as a chéile (cé nach raibh sé sin ar eolas acu ag an am) agus ré nua an choilíneachais (agus an chaipitleachais) ag teacht isteach ina áit. Roghnaigh muid na foinsí de réir an bhirt díchoilíniú a dhéanamh trí fhoinsí a chur ar fáil (go háirithe i nGaeilge) a ndéantar neamhaird orthu chomh minic sin is go mbíonn ár dtuiscint ar Éirinn sa Nua-Aois Luath anois claonta.  Sa phróiseas seo, thug muid aird ar cháilíocht liteartha, tábhacht stairiúil, éagoitiantacht, agus, astu ar fad, bhí muid ag iarraidh an méid dearcadh, ábhair, agus teangacha agus is féidir a thaispeáint don úsáideoir le tír ag dul trí na mórathruithe seo ar fad a léiriú. Tá sé chatagóir againn ar an léarscáil, agus titeann gach ceann den 197 téacs faoi cheann acu: pátrúnacht agus tiarnas, saothrú cultúrtha, straitéis agus polaitíocht, coinbhleacht agus foréigean, ceol agus siamsaíocht, aibhneacha agus aigéan, tuath agus talamh. (tuilleadh eolais faoin <Link to='/deep-mapping#' >Léarscáil Dhomhain anseo</Link>). Mar a bhíonn i gceist le gach léarscáil den sórt seo, is léirshamhlú é nach bhfuil go hiomlán údarásach. Caithfear na téacsanna agus na daoine a chuirtear uirthi a roghnú; thar aon rud eile tá sé i gceist aici aird a tharraingt ar na cultúir éagsúla seo ag teacht le chéile in aimsir choinbhleachta le taiscéalaíocht tríd an chorpas agus níos mó taighde a spreagadh. </p>
                            </div>

                        </div>

                        <p className='para-style'><strong>Eolas Teicniúil: </strong></p>

                        <p className='para-style'><strong>Teacht ar shonraí</strong></p>


                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'>Is féidir teacht ar na sonraí ar fad trí chomhéadan shuíomh MACMORRIS, ach tá an rogha ag úsáideoirí chomh maith sonraí a íoslódáil trí uirlisí easpórtála an choráis chuardaigh. Is féidir torthaí cuardach a easpórtáil mar chomhad .csv, ar féidir é a oscail i bhfeidhmchláir ar nós Excel. </p>

                            </div>
                        </div>

                        <p className='para-style'><strong>Ag déanamh tagartha</strong></p>

                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'>Tá ár sonraí ceadúnaithe faoi <a href='https://creativecommons.org/licenses/by-nc/4.0/'> Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0).</a>
                                </p>
                                <p className='para-style'>
                                    Le tagairt a dhéanamh do dhuine, saothar, líonra nó áit ar leith ar an Léarscáil Dhomhain, is féidir an modh tagartha seo a úsáid: ‘Macmorris, URL, dáta’.
                                </p>

                            </div>
                        </div>

                        <p className='para-style'><strong>Léargas ginearálta ar an chóras bunachair sonraí </strong></p>

                        <div className='case-flex-column'>
                            <div className='para-flex'>

                                <p className='para-style'>
                                    Tógadh an feidhmchlár gréasáin leis an chreatlach Ruby on Rails, uirlis forbartha gréasáin a bhfuil clú uirthi as a héifeachtúlacht agus a hinscálaitheacht. Tógadh an bunachar sonraí le postgreSQL, córas foinse-oscailte do bhainistiú bunachar sonraí coibhneasta, agus cuirtear é leis an chreatlach Rails le maoirseacht agus cothbháil a dhéanamh ar chúl an fheidhmchláir.
                                </p>
                                <p className='para-style'>
                                    Tógadh an comhéadan úsáideora don bhunachar sonraí le React JS, agus don Léarscáil Dhomhain d'úsáid muid Mapbox agus deck.gl freisin (leabharlann dhigiteach chumhachtach sholúbtha do léirshamhlú sonraí ar léarscáileanna digiteacha) ag tabhairt faoi léirshamhlú sonraí ar mhodh srathach. D'úsáid muid react-force-graph (leabharlann dhigiteach React a forbaíodh do thógáil léirshamhluithe líonraí 2T agus 3T) do chomhéadan úsáideora na Líonraí. Chun ardchaighdeán freagrúlachta agus idirghníomhaíochta a bhaint amach, tarraingíonn comhéadan úsáideora na léarscáile agus na léirshamhluithe líonraí ón GPU. Trí leas a bhaint as cumas ríomhaireachtúil an GPU, is féidir leis na léirshamhluithe seo tásacanna casta rindreála a láimhseáil, agus beochan réidh a chur ar fáil.
                                </p>

                                {/* <p className='para-style'>For more detailed technical documentation and our open-source code base, see our Github repository</p> */}
                            </div>
                        </div>

                        <p className='para-style'><strong>Comhcheangail</strong></p>
                        <div className='case-flex-column'>
                            <div className='para-flex'>

                                <p className='para-style'>Níorbh fhéidir an tionscadal seo a chur i gcrích murach an comhoibriú agus an chabhair a fuair muid ó institiúidí éagsúla agus ónár gcomhghleacaithe. </p>


                            </div>
                        </div>
                        <p className='para-style'><strong>Institiúidí Cleamhnaithe</strong></p>
                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'>
                                    <a href='https://www.dib.ie/'>
                                        Díolaim Beathaisnéisí na hÉireann</a>:
                                    Gabhann muid ár mbuíochas le foireann DIB as comhaid cúil .xml DIB a roinnt linn, ónar tharraing muid na nascanna líonraí.
                                </p>
                                <p className='para-style'><a href='https://bardic.celt.dias.ie/'>Institiúid Ard-Léinn Bhaile Átha Cliath</a>:
                                    Gabhann muid ár mbuíochas leis an Institiúid Ard-Léinn as meiteashonraí cúil Bhunachar Sonraí Fhilíocht na Scol a roinnt linn, ónar tharraing muid ár liostaí dánta, filí, pátrún agus líonraí pátrúnachta.
                                </p>
                                <p className='para-style'>
                                    <a href='https://www.Léamh.org/'>
                                        Léamh</a>: Gabhann muid ár mbuíochas le foireann Léamh as comhoibriú linn, agus as sleachta éagsúla as dánta ónár gcás-staidéar ar Chúige Mumhan a chuir muid in eagar agus a d'aistrigh muid a chur suas ar an suíomh s'acu.

                                </p>
                                <p className='para-style'>
                                    <a href='https://www.maynoothuniversity.ie/experiential-learning/students/research'>Scéim SPUR Ollscoil Mhá Nuad</a>:
                                    Gabhann muid ár mbuíochas le foireann SPUR as trí mhiontionscadal mhac léinn nasctha le MACMORRIS a mhaoiniú agus a éascú idir 2020 agus 2023. Buíochas le hÉabha Puirséil as an obair a rinne sí ar leabharliosta MACMORRIS agus ar dhán atá á chur suas ar Léamh.org. Buíochas le Conor Killian as an obair a rinne sé ar ghlanadh sonraí agus ar iontráil sonraí don mheiteasonraí beathaisnéise agus do na líonraí. Buíochas le Emma Cooling as an obair a rinne sí ar acmhainní foghlama MACMORRIS.

                                </p>
                                <p className='para-style'>
                                    <a href='https://maynoothuniversity.ie/external-relations'>
                                        Oifig Cumarsáide Ollscoil Mhá Nuad</a>: Buíochas le Daniel Balteanu agus le foireann na hOifige Cumarsáide as a bheith ag obair linn leis na físeáin ar an suíomh a chruthú. Buíochas le hAlan Waldron as guthú a dhéanamh ar an bhfíseán teagaisc
                                </p>
                            </div>
                        </div>

                        <p className='para-style'><strong>
                            Scoláirí Cleamhnaithe</strong></p>
                        <ol className='ordered-affiliates'>
                            <li><a href='https://englishcomplit.unc.edu/faculty-directory/david-john-baker/'>David Baker (English and Comparative Literature, UNC Chapel Hill)</a></li>
                            <li><a href='https://www.universityofgalway.ie/our-research/people/english-and-creative-arts/marielouisecoolahan/'>Marie-Louise Coolahan (English and Creative Arts, NUI Galway)</a></li>
                            <li><a href='https://history.uconn.edu/faculty-by-name/brendan-kane/'>Brendan Kane (History, UCONN)</a></li>
                            <li><a href='https://www.maynoothuniversity.ie/people/stephen-oneill'>Stephen O'Neill (English, Maynooth)</a></li>
                            <li><a href='https://www.maynoothuniversity.ie/people/kevin-tracey'>Kevin Tracey (English, Maynooth)</a></li>
                            <li><a href='https://www.gla.ac.uk/schools/critical/staff/willymaley/'>Willy Maley (Critical Studies, University of Glasgow)</a></li>
                            <li><a href='https://pure.royalholloway.ac.uk/en/persons/deana-rankin'>Deana Rankin (English and Drama, Royal Holloway)</a></li>
                            <li><a href='https://people.ucd.ie/marc.caball'>Marc Caball (History, UCD)</a></li>
                            <li><a href='https://www.hope.ac.uk/si/dr-ruth-canning.html'>Ruth Canning (History and Politics, Liverpool Hope)</a></li>
                            <li><a href='https://people.ucd.ie/jane.grogan'>Jane Grogan (English, Drama and Film, UCD)</a></li>
                            <li><a href='https://profiles.sussex.ac.uk/p131314-andrew-hadfield/about'>Andrew Hadfield (English, Sussex)</a></li>
                            <li><a href='https://people.tcd.ie/Profile?Username=mhoyne'>Mícheál Hoyne (Gaeilge, TCD)</a></li>
                            <li><a href='https://mooreinstitute.ie/about-us/core-staff/'>David Kelly (Digital Humanities Manager, NUI Galway)</a></li>
                            <li><a href='https://irishstudies.nd.edu/scholars/faculty-fellows/sarah-mckibben/'>Sarah McKibben (Irish Studies, Notre Dame)</a></li>
                            <li><a href='https://www.maynoothuniversity.ie/people/eoghan-raghallaigh'>Eoghan Ó Raghallaigh (Gaeilge, Maigh Nuad)</a></li>
                            <li><a href='https://pure.au.dk/portal/en/persons/isabelle-torrance(c9ba2efc-ef38-4bf2-8061-ae5f33b8cb0a).html'>Isabelle Torrance (English, Aarhus)</a></li>
                        </ol>
                        <p className='para-style'><strong>
                            Buíochas
                        </strong></p>

                        <div className='case-flex-column'>
                            <div className='para-flex'>
                                <p className='para-style'>
                                    Ba mhaith linn ár mbuíochas a ghabháil leis na hinstitiúidí seo thíos as cead a thabhairt dúinn íomhánna óna mbailiúcháin a úsáid:
                                </p>
                                <ol className='ordered-affiliates'>
                                    <li><a href='https://www.bl.uk/'>Leabharlann na Breataine</a></li>
                                    <li><a href='https://marshlibrary.ie'>Leabharlann an Ardeaspaig Marsh</a></li>
                                    <li><a href='https://www.nli.ie/'>Leabharlann Náisiúnta na hÉireann</a></li>
                                    <li><a href='https://www.rmg.co.uk/national-maritime-museum'>National Maritime Museum, Londain</a></li>
                                    <li><a href='https://www.tcd.ie/library/'>Leabharlann Choláiste na Tríonóide, Baile Átha Cliath</a></li>
                                    <li><a href='https://www.ria.ie/library'>Acadamh Ríoga na hÉireann</a></li>
                                </ol>
                            </div>
                        </div>

                    </>

                }
            </Container>
        </>
    );
}

export default AboutIrish;
