import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import EmailOutline from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';


function Biography({ show, handleClose, data, changeIrish }) {

  return (
    <Modal show={show} size="lg" backdrop="static" keyboard={false} style={{ 'zIndex': 90000 }}>
      <Modal.Header>
        <div style={{ "display": "flex", "flexDirection": "column" }}>
          <Modal.Title style={{ "color": "#a22828" }}>{data.name}</Modal.Title>
          <span style={{ "color": "#808080" }}>{
            changeIrish ?
              <>
                {data.ie_title}
              </> :
              <>
                {data.title}
              </>
          }</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ "display": "flex", "flexDirection": "column" }}>
          <div>
            {
              changeIrish ?
                data.ie_text.split('\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    <div>{line}</div>
                    <br />
                  </React.Fragment>
                ))
                :
                data.text.split('\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    <div>{line}</div>
                    <br />
                  </React.Fragment>
                ))
            }
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={
                <a target="_top"
                  rel="noopener noreferrer"
                  href={`mailto:${data.email}`}>
                  <IconButton color='primary'>
                    <EmailOutline />
                  </IconButton>
                </a>
              }
              label={data.email}
              labelPlacement="end"
              style={{ color: 'grey' }}
            />
            {data?.twitter != null &&
              <FormControlLabel
                control={
                  <a target="_top"
                    rel="noopener noreferrer"
                    href={`https://twitter.com/${data.twitter}`}>
                    <IconButton color='primary'>
                      <TwitterIcon />
                    </IconButton>
                  </a>
                }
                style={{ color: 'grey' }}
                label={data.twitter}
                labelPlacement="end"
              />
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button btnsecondary pagescroll" style={{ "margin": "10px", "visibility": "visible" }}
          onClick={handleClose}>Close</div>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(Biography);
